<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <v-card class="pa-4" height="100%" elevation="1" rounded="lg">
    <!-- Title -->
    <p class="ma-2 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_referral_simple.title') }}
    </p>
    <v-row>
      <v-col v-show="!version" cols="12" sm="12" md="12" lg="12" xl="12">
        <v-list-item three-line>
          <!-- Icon -->
          <v-img
            class="mt-5 mb-auto ml-0 mr-4"
            src="../../../public/images/icons/freunde-werben.svg"
            max-width="36"></v-img>
          <v-list-item-content>
            <!-- Text -->
            <v-card-text
              class="px-0 py-2 mb-2 ft-500"
              v-html="$vuetify.lang.t('$vuetify.widget_referral_simple.text')">
            </v-card-text>
            <!-- Button -->
            <v-btn
              :to="{ path: 'referral' }"
              max-width="220px"
              height="45"
              class="mt-4 text-none ft-700"
              color="primary"
              elevation="0"
              >{{ $vuetify.lang.t('$vuetify.widget_referral_simple.button_text') }}</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['version', 'callText'],
  data() {
    return {}
  },
}
</script>

<style scoped>
.list-item-widgetContact {
  height: 100%;
}
</style>
