<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <v-hover>
    <template #default="{ hover }">
      <v-card class="pa-3" :elevation="hoverable && hover ? 6 : 1" rounded="lg" height="100%">
        <v-list-item three-line>
          <!-- Icon -->
          <v-img
            v-if="icon"
            class="mt-2 mb-auto ml-0 mr-4"
            :src="getImgUrl(`icons/${icon}.svg`)"
            max-width="32"></v-img>

          <v-list-item-content>
            <!-- Title -->
            <v-list-item-title class="mb-1 ft-700">{{ title }}</v-list-item-title>
            <!-- Content -->
            <v-card-text v-if="content" class="px-0 py-2 ft-500" v-html="content"></v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-card>
    </template>
  </v-hover>
</template>
<script>
/* eslint-env node */

export default {
  props: ['title', 'content', 'icon', 'hoverable'],
  data() {
    return {}
  },
  methods: {
    getImgUrl(imgUrl) {
      return require('../../../../public/images/' + imgUrl)
    },
  },
}
</script>
