<template>
  <v-card class="pa-3" elevation="1" rounded="lg" height="100%">
    <v-list-item three-line>
      <v-list-item-content>
        <!-- Title -->
        <v-list-item-title class="mb-4 ft-700">
          <v-row>
            <v-col>
              {{ title }}
            </v-col>
            <v-col>
              <!-- Change icon and tooltip -->
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-img
                    v-bind="attrs"
                    style="float: right"
                    src="../../../public/images/google_icons/help_outline_red_24dp.svg"
                    max-width="24"
                    v-on="on"></v-img>
                </template>
                <span>
                  {{ $vuetify.lang.t('$vuetify.widget_data.popup') }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-list-item-title>
        <!-- Data array display -->
        <v-card-text class="px-0 py-2">
          <v-row
            align="center"
            v-for="(item, index) in data"
            :key="index"
            dense
            :style="`${index != data.length - 1 ? '' : ''} `">
            <v-col class="py-0 ft-700-14" cols="6"><span v-html="item.key"></span></v-col>
            <v-col align="right" class="py-0 ft-500" cols="6">{{ item.value }}</v-col>
          </v-row>
        </v-card-text>
      </v-list-item-content>
    </v-list-item>
  </v-card>
</template>
<script>
export default {
  props: ['title', 'data'],
  data() {
    return {}
  },
}
</script>
