<template>
  <div>
    <HeaderComponent
      :title="$vuetify.lang.t('$vuetify.contract_overview.header_title')"
      :location="location"
      class="mb-14" />
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.contract_overview.widget_data_contract_title')"
          :data="contractData" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.contract_overview.widget_data_supply_address_title')"
          :data="supplyData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(widget, index) in widgetSimpleImageDataList"
        :key="index"
        cols-12
        sm="6"
        md="4"
        lg="4"
        xl="4">
        <WidgetSimpleImage
          :title="widget.title"
          :content="widget.content"
          :image="widget.image"
          :link="widget.link"
          :href="widget.href" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent'
import WidgetSimpleImage from '../components/widgets/dashboard/WidgetSimpleImage'
import WidgetData from '../components/widgets/WidgetData.vue'
import { getCustomerContract } from '../api/api'
import { mapState } from 'vuex'

export default {
  name: 'ContractOverview',
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.contract_overview.header_location'),
          disabled: false,
          href: '/contract-overview',
        },
      ],
      widgetSimpleImageDataList: [
        {
          title: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_1_title'),
          content: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_1_content'),
          image: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_1_image'),
          link: 'help-and-contact/move',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_2_title'),
          content: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_2_content'),
          image: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_2_image'),
          link: 'help-and-contact/faq',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_3_title'),
          content: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_3_content'),
          image: this.$vuetify.lang.t('$vuetify.contract_overview.widget_simple_image_3_image'),
          href: 'https://www.veganstrom.com/',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
    }),
    contractData: function () {
      return [
        {
          key: this.$vuetify.lang.t(
            '$vuetify.contract_overview.widget_data_contract_contract_number'
          ),
          value: this.contract_selected.contract_number
            ? this.contract_selected.contract_number
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_contract_meter_number'),
          value: this.contract_selected.meter_number ? this.contract_selected.meter_number : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_supply_start'),
          value: this.contract_selected.supply_start_date
            ? new Date(this.contract_selected.supply_start_date).toLocaleDateString('de-DE')
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_contract_start'),
          value: this.contract_selected.contract_start_date
            ? new Date(this.contract_selected.contract_start_date).toLocaleDateString('de-DE')
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_contract_contractor'),
          value: this.customer_selected.name ? this.customer_selected.name : '--',
        },
      ]
    },
    supplyData: function () {
      return [
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_supply_address_street'),
          value: this.contract_selected.supply_address.street
            ? this.contract_selected.supply_address.street
            : '--',
        },
        {
          key: this.$vuetify.lang.t(
            '$vuetify.contract_overview.widget_data_supply_address_house_number'
          ),
          value: this.contract_selected.supply_address.street_number
            ? this.contract_selected.supply_address.street_number
            : '--',
        },
        {
          key: this.$vuetify.lang.t(
            '$vuetify.contract_overview.widget_data_supply_address_address_addition'
          ),
          value: this.contract_selected.supply_address.address_addition
            ? this.contract_selected.supply_address.address_addition
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_supply_address_plz'),
          value: this.contract_selected.supply_address.zip_code
            ? this.contract_selected.supply_address.zip_code
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.contract_overview.widget_data_supply_address_city'),
          value: this.contract_selected.supply_address.city
            ? this.contract_selected.supply_address.city
            : '--',
        },
        {
          key: this.$vuetify.lang.t(
            '$vuetify.contract_overview.widget_data_supply_address_district'
          ),
          value: this.contract_selected.supply_address.district
            ? this.contract_selected.supply_address.district
            : '--',
        },
      ]
    },
  },
  mounted() {
    getCustomerContract()
    this.$store.commit('set_drawer_selected', 2)
  },
  components: { WidgetData, HeaderComponent, WidgetSimpleImage },
}
</script>
