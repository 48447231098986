/* eslint-env node */

import axios from 'axios'
import CryptoJS from 'crypto-js'
import store from '../store/store.js'
import router from '../router/index'

// Settings
const api_base = process.env.VUE_APP_API_BASE
const ui_base = process.env.VUE_APP_UI_BASE
const client_id = process.env.VUE_APP_AUTH_CLIENT
const redirect_url = process.env.VUE_APP_AUTH_REDIRECT_URL

// local auth-code-client-local-portal-ui
const scope = 'offline+customer' //TODO client is not allowed the scope refresh_token
export const settings_url =
  ui_base + `/settings?return_to=${process.env.VUE_APP_AUTH_RETURN_TO}`.split('/').join('%2F')
// ---------------------------------------------------
// Api config
const auth_instance = axios.create({
  baseURL: api_base,
  timeout: 5000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

export function getInitialAuthUrl(redirect_data) {
  // Genrate 128 character code_verifier and use SHA256 to generate code_challenge
  var code_verifier = generateRandomString(128)
  var code_challenge = generateCodeChallenge(code_verifier)
  // Save the code_verifier for requesting the access_token later
  store.commit('set_code_verifier', code_verifier)
  store.commit('set_redirect_data', redirect_data)

  // Initiate the auth code flow
  const state = generateRandomString(128)
  var authUrl = `${api_base}/oauth2/auth?client_id=${client_id}&response_type=code&redirect_uri=${redirect_url
    .split('/')
    .join(
      '%2F'
    )}&scope=${scope}&state=${state}&code_challenge=${code_challenge}&code_challenge_method=S256&prompt=login`
  return authUrl
}

// export function getLogoutAuthUrl() {
//   var authUrl = `${api_base}/oauth2/sessions/logout`;
//   return authUrl;
// }

export function getLogoutAuthUrl() {
  var authUrl =
    `${process.env.VUE_APP_UI_BASE}/logout?return_to=` + process.env.VUE_APP_AUTH_RETURN_TO
  return authUrl
}

export async function getAccessToken(code) {
  store.commit('set_loading', true)
  // Create POST request for exchanging the code_verifier and code for the access_token
  const params = new URLSearchParams()
  params.append('client_id', client_id)
  params.append('code_verifier', store.getters.code_verifier)
  params.append('code', code)
  params.append('grant_type', 'authorization_code')
  params.append('redirect_uri', redirect_url)

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  // Initiate the exchange
  try {
    const res = await auth_instance.post('/oauth2/token', params, config)
    // Save the access_token, (id_token), (refresh_token) and expiry time
    store.commit('set_user_data', res.data)
    store.commit('set_loading', false)
    router.push({ path: store.getters.redirect_data.path })
  } catch (error) {
    console.log(error)
    store.commit('set_loading', false)
  }
}

export async function checkAndRefreshAccessToken() {
  store.commit('set_loading', true)
  // Check if access is expired
  let now = new Date()
  let expiryDate = new Date(store.state.expiration_time)
  if (now < expiryDate) {
    store.commit('set_loading', false)
    return
  }
  // Create POST request for exchanging the refresh_token for the new access_token
  const params = new URLSearchParams()
  params.append('client_id', client_id)
  params.append('code_verifier', store.getters.code_verifier)
  params.append('refresh_token', store.getters.refresh_token)
  params.append('grant_type', 'refresh_token')

  const config = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  }
  // Initiate the exchange
  try {
    const res = await auth_instance.post('/oauth2/token', params, config)
    // Save the access_token, (id_token), (refresh_token) and expiry time
    store.commit('refresh_access_token', res.data)
    store.commit('set_loading', false)
  } catch (error) {
    console.log(error)
    store.commit('set_loading', false)
  }
}

// Helper functions ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

function generateRandomString(length) {
  var text = ''
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~'
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

function generateCodeChallenge(code_verifier) {
  return base64URL(CryptoJS.SHA256(code_verifier))
}

function base64URL(string) {
  return string
    .toString(CryptoJS.enc.Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}
