<template>
  <div>
    <v-container grid-list-xs>
      <v-row>
        <v-col>
          <v-img
            v-if="!$vuetify.breakpoint.mobile"
            class="rounded-lg"
            src="../../public/images/error-page.png"></v-img>
          <v-img
            v-if="$vuetify.breakpoint.mobile"
            class="rounded-lg"
            src="../../public/images/error-page-mobile.png"></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col>
          <center>
            <v-btn height="45" color="accent" class="text-none ft-700" @click="reset">
              {{ $vuetify.lang.t('$vuetify.error_page.reload') }}
            </v-btn>
          </center>
        </v-col>
      </v-row>
    </v-container>
    <p></p>
  </div>
</template>

<script>
export default {
  methods: {
    reset() {
      this.$store.commit('logout')
    },
  },
}
</script>

<style></style>
