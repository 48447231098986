<template>
  <div v-if="!['Registration'].includes($route.name)">
    <v-navigation-drawer
      v-model="drawer"
      :temporary.sync="temporary"
      :permanent="permanent"
      :mini-variant.sync="mini"
      app
      class="pl-1">
      <!-- Logo -->
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="mb-14 mt-2">
            <!-- Normal -->
            <v-img v-show="!mini" src="../../public/images/vegan_strom.png" max-width="194"></v-img>

            <!-- Mobile -->
            <v-img
              v-show="mini"
              src="../../public/images/vegan_strom_small.png"
              max-width="190"></v-img>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- Navigation list -->
      <v-list dense nav>
        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, index) in drawerItems"
            :key="index"
            class="mb-4"
            link
            :to="'/' + item.url"
            :value="index">
            <!-- Icon -->
            <v-list-item-icon class="mr-5">
              <!-- Active -->
              <v-img
                v-if="drawer_selected == index"
                :src="getImgUrl(`google_icons/${item.icon}_active.svg`)"></v-img>
              <!-- Inactive -->
              <v-img
                v-if="drawer_selected != index"
                :src="getImgUrl(`google_icons/${item.icon}.svg`)"></v-img>
            </v-list-item-icon>
            <!-- Text -->
            <v-list-item-content>
              <v-list-item-title
                :class="
                  'ft-700 text-navigation-drawer ' +
                  (drawer_selected == index ? 'primary--text' : '')
                ">
                {{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- Mobile navigation -->
    <v-app-bar v-show="$vuetify.breakpoint.xs" color="white">
      <v-row dense class="pa-0 ma-0" align="center">
        <v-col>
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          <span class="text--caption" style="vertical-align: middle">
            {{ $vuetify.lang.t('$vuetify.navigation_drawer.menu') }}
          </span>
        </v-col>
        <v-col>
          <v-img src="../../public/images/vegan_strom.png" max-width="124"></v-img>
        </v-col>
        <v-col class="text-right">
          <span class="mr-4" style="cursor: pointer" @click="settings">
            <v-icon color="black" size="x-large"> mdi-account-cog-outline </v-icon>
          </span>
          <span style="cursor: pointer" @click="logout">
            <v-icon color="black" size="x-large">mdi-power-standby</v-icon>
          </span>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>
<script>
/* eslint-env node */

import { settings_url } from '../auth/auth'

export default {
  data() {
    return {
      drawer: false,
      drawerItems: [
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_dashboard'),
          url: '',
          icon: 'space_dashboard_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_my_data'),
          url: 'my-data',
          icon: 'folder_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_contract_overview'),
          url: 'contract-overview',
          icon: 'assignment_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_tarif_overview'),
          url: 'tarif-overview',
          icon: 'list_alt_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_documents'),
          url: 'documents',
          icon: 'description_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_metering'),
          url: 'metering',
          icon: 'bolt_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_referral'),
          url: 'referral',
          icon: 'mood_black_24dp',
        },
        // { TODO no newsletter for now
        //   title: this.$vuetify.lang.t(
        //     "$vuetify.navigation_drawer.drawer_item_newsletter"
        //   ),
        //   url: "newsletter",
        //   icon: "mail_black_24dp 1",
        // },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_help_and_contact'),
          url: 'help-and-contact/overview',
          icon: 'contact_support_black_24dp',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.navigation_drawer.drawer_item_sepa'),
          url: 'SEPA-mandate',
          icon: 'bank_outline',
        },
        // {
        //   title: this.$vuetify.lang.t(
        //     "$vuetify.navigation_drawer.drawer_item_my_co2_check"
        //   ),
        //   url: "my-co2-check",
        //   icon: "calculate",
        // },
      ],
    }
  },
  computed: {
    // Small none mobile navigation toggle
    mini: {
      get: function () {
        if (this.$vuetify.breakpoint.mdAndDown && !this.$vuetify.breakpoint.xs) {
          return true
        }
        return false
      },
      set: function () {},
    },
    permanent: function () {
      if (this.$vuetify.breakpoint.xs) {
        return false
      }
      return true
    },
    temporary: function () {
      if (this.$vuetify.breakpoint.xs) {
        return true
      }
      return false
    },
    drawer_selected: {
      get: function () {
        return this.$store.state.drawer_selected
      },
      set: function (value) {
        this.$store.commit('set_drawer_selected', value)
      },
    },
  },
  methods: {
    getImgUrl(imgUrl) {
      return require('../../public/images/' + imgUrl)
    },
    logout() {
      this.$store.commit('logout')
    },
    settings() {
      document.location = settings_url
    },
  },
}
</script>
