<template>
  <div>
    <HeaderComponent
      :title="$vuetify.lang.t('$vuetify.tarif_overview.header_title')"
      :location="location"
      class="mb-14" />
    <v-row v-if="anticipated_payments && anticipated_payments.length > 1" dense>
      <v-col class="py-0 pl-4" cols="12" offset-md="6" md="6" style="display: flex" @click.stop>
        <v-row>
          <v-col cols="7">
            <h3 class="mr-6 mt-1">Ab<wbr />schlags<wbr />zeit<wbr />raum:</h3>
          </v-col>
          <v-col cols="5">
            <v-select
              v-model="anticipated_payment_selected"
              class="ft-700-24"
              solo
              dense
              :items="anticipated_payments"
              item-text="time_period"
              return-object
              width="100px"></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.tarif_overview.widget_data_tarif_title')"
          :data="tarifData" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.tarif_overview.widget_data_deduction_title')"
          :data="deductionData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetContact
          version="tarif"
          :call-text="$vuetify.lang.t('$vuetify.tarif_overview.widget_contact_call_text')" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetReferralSimple />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent'
import WidgetData from '../components/widgets/WidgetData.vue'
import WidgetContact from '../components/widgets/dashboard/WidgetContact.vue'
import WidgetReferralSimple from '../components/widgets/WidgetReferralSimple.vue'
import { getCustomerContract } from '../api/api'
import { mapState } from 'vuex'

export default {
  name: 'TarifOverview',
  components: { WidgetData, HeaderComponent, WidgetContact, WidgetReferralSimple },
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.tarif_overview.header_location'),
          disabled: false,
          href: '/tarif-overview',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
      anticipated_payment_selected: state => state.anticipated_payment_selected,
      anticipated_payments: state => state.contract_selected.anticipated_payments,
    }),
    anticipated_payment_selected: {
      get: function () {
        return this.$store.getters.anticipated_payment_selected
      },
      set: function (newValue) {
        this.$store.commit('set_anticipated_payment_selected', newValue)
      },
    },
    tarifData: function () {
      return [
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_tarif_contract_number'),
          value: this.contract_selected.contract_number
            ? this.contract_selected.contract_number
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_tarif_meter_number'),
          value: this.contract_selected.meter_number ? this.contract_selected.meter_number : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_tarif_name'),
          value: this.anticipated_payment_selected.tariff_name
            ? this.anticipated_payment_selected.tariff_name
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_tarif_contract_start'),
          value: this.contract_selected.contract_start_date
            ? new Date(this.contract_selected.contract_start_date).toLocaleDateString('de-DE')
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_tarif_contract_end'),
          value: this.anticipated_payment_selected.contract_term
            ? this.anticipated_payment_selected.contract_term
            : '--',
        },
        {
          key: this.$vuetify.lang.t(
            '$vuetify.tarif_overview.widget_data_tarif_price_guarantee_date'
          ),
          value: this.contract_selected.price_guarantee_end_date
            ? new Date(this.contract_selected.price_guarantee_end_date).toLocaleDateString('de-DE')
            : '--',
        },
        {
          key: this.$vuetify.lang.t(
            '$vuetify.tarif_overview.widget_data_tarif_contract_notice_period'
          ),
          value: this.$vuetify.lang.t(
            '$vuetify.tarif_overview.widget_data_tarif_contract_notice_period_value'
          ),
        },
      ]
    },
    deductionData: function () {
      return [
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_deduction_street'),
          value: this.contract_selected.supply_address.street
            ? this.contract_selected.supply_address.street
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_deduction_consumption'),
          value: this.anticipated_payment_selected.consumption
            ? this.anticipated_payment_selected.consumption + ' kWh'
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_deduction_base_price'),
          value: this.anticipated_payment_selected
            ? this.anticipated_payment_selected.base_price
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_deduction_energy_price'),
          value: this.anticipated_payment_selected
            ? this.anticipated_payment_selected.energy_price
            : '--',
        },
        {
          key: this.$vuetify.lang.t(
            '$vuetify.tarif_overview.widget_data_deduction_anticipated_payment'
          ),
          value: this.anticipated_payment_selected.anticipated_payment
            ? this.anticipated_payment_selected.anticipated_payment
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.tarif_overview.widget_data_deduction_payment_method'),
          value: 'monatlich',
        },
      ]
    },
  },
  mounted() {
    getCustomerContract()
    this.$store.commit('set_drawer_selected', 3)
  },
}
</script>
