import { en } from 'vuetify/es5/locale'

export default {
  ...en,

  overview: 'overview',
  contracts: 'contracts',
  invoices: 'invoices',
  messaging: 'messaging',
  signup: 'Sign-up friends for bonuses!',
}
