<template>
  <v-card class="pa-3" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-card-title class="mb-1 ml-4 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_metering.title') }}
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <!-- Table -->
          <v-data-table
            :headers="meter_readings_by_contract_headers"
            :items="meter_readings_by_contract"
            :items-per-page="5">
            <template #item="{ item }">
              <tr class="ft-700">
                <td class="ft-500">{{ item.meter_number }}</td>
                <td class="ft-500">{{ item.reading_date }}</td>
                <td class="ft-500">{{ item.meter_reading + ' kWh' }}</td>
                <!-- <td class="ft-500">{{ item.reason_dso_accurate }}</td> -->
                <td class="ft-500">{{ item.source }}</td>
                <td class="ft-500">{{ item.billing_gross_amount_sum }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { getMeterReadingsByContractId } from '../../api/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      tab: 0,
      meter_readings_by_contract_headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.widget_metering.metering_header_meter_number'),
          value: 'meter_number',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.widget_metering.metering_header_reading_date'),
          value: 'reading_date',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.widget_metering.metering_header_meter_reading'),
          value: 'meter_reading',
        },
        // {
        //   text: this.$vuetify.lang.t(
        //     "$vuetify.widget_metering.metering_header_reason_dso_accurate"
        //   ),
        //   value: "reason_dso_accurate",
        // },
        {
          text: this.$vuetify.lang.t('$vuetify.widget_metering.metering_header_source'),
          value: 'source',
        },
      ],
    }
  },
  mounted() {
    getMeterReadingsByContractId()
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
      meter_readings_by_contract: state => state.meter_readings_by_contract,
    }),
  },
}
</script>
