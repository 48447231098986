<template>
  <v-card class="pa-4" elevation="1" rounded="lg" height="100%">
    <v-card-title class="mb-1 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_newsletter_sub.title') }}
    </v-card-title>
    <v-card-text class="ft-500">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-col class="pl-0" cols="12" md="8" lg="6">
          <p>
            {{ $vuetify.lang.t('$vuetify.widget_newsletter_sub.text') }}
          </p>
        </v-col>
        <v-row dense>
          <v-col cols="12" md="8" lg="6">
            <v-radio-group v-model="radioGroup" row>
              <v-radio v-for="n in 3" :key="n" :value="n" color="accent">
                <template #label>
                  <div class="ft-500">{{ raidoOptions[n - 1] }}</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="8" lg="6" class="pr-3">
            <v-text-field
              v-model="firstName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_newsletter_sub.first_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="8" lg="6" class="pr-3">
            <v-text-field
              v-model="lastName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_newsletter_sub.last_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" md="8" lg="6" class="pr-3">
            <v-text-field
              v-model="email"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_newsletter_sub.email')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="8" lg="6">
            <v-btn
              height="45"
              :disabled="!valid"
              color="accent"
              class="mr-4 mb-1 text-none ft-700"
              @click="validate">
              {{ $vuetify.lang.t('$vuetify.widget_newsletter_sub.button_text_subscribe') }}
            </v-btn>

            <!-- <v-btn
              height="45"
              color="primary"
              class="mr-4 mb-1 text-none ft-700"
              @click="reset"
            >
              {{
                $vuetify.lang.t(
                  "$vuetify.widget_newsletter_sub.button_text_reset"
                )
              }}
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      firstName: '',
      lastName: '',
      email: '',
      fieldRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_newsletter_sub.text_required'),
      ],
      radioGroup: 1,
      raidoOptions: [
        this.$vuetify.lang.t('$vuetify.widget_newsletter_sub.radio_woman'),
        this.$vuetify.lang.t('$vuetify.widget_newsletter_sub.radio_man'),
        this.$vuetify.lang.t('$vuetify.widget_newsletter_sub.radio_other'),
      ],
    }
  },

  methods: {
    validate() {
      this.$refs.form.validate()
    },
    // reset() {
    //   this.$refs.form.reset();
    // },
  },
}
</script>
