<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <v-card class="pa-4" height="100%" elevation="1" rounded="lg">
    <!-- Title -->
    <p class="ma-2 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_contact.title') }}
    </p>
    <v-row>
      <v-col v-show="!version" cols="12" sm="12" md="6" lg="6" xl="6">
        <v-list-item three-line>
          <!-- Left icon -->
          <v-img
            class="mt-5 mb-auto ml-0 mr-4"
            src="../../../../public/images/icons/mailverkehr.svg"
            max-width="36"></v-img>
          <v-list-item-content>
            <!-- Left text -->
            <v-card-text
              class="px-0 py-2 mb-2 ft-500"
              v-html="$vuetify.lang.t('$vuetify.widget_contact.text_contact')">
            </v-card-text>
            <!-- Left button -->
            <v-btn
              :to="{ path: 'help-and-contact/email' }"
              max-width="220px"
              height="45"
              class="mt-4 text-none ft-700"
              color="primary"
              elevation="0"
              >{{ $vuetify.lang.t('$vuetify.widget_contact.button_text_contact') }}</v-btn
            >
          </v-list-item-content>
        </v-list-item></v-col
      >

      <v-divider v-if="!$vuetify.breakpoint.sm && !version" vertical class="my-5"></v-divider>

      <v-col
        cols="12"
        sm="12"
        :md="version == 'documents' ? 8 : version == 'tarif' ? 12 : 6"
        :lg="version == 'documents' ? 8 : version == 'tarif' ? 12 : 6"
        :xl="version == 'documents' ? 8 : version == 'tarif' ? 12 : 6">
        <v-divider v-if="$vuetify.breakpoint.smAndDown && !version" class="my-1"></v-divider>

        <v-list-item three-line class="list-item-widgetContact">
          <!-- Right icon -->
          <v-img
            class="mt-5 mb-auto ml-0 mr-4"
            src="../../../../public/images/icons/rueckruf.svg"
            max-width="36"></v-img>
          <v-list-item-content>
            <!-- Right text -->
            <v-card-text class="px-0 py-2 mb-auto">{{ callText }} </v-card-text>
            <!-- Right button -->
            <v-btn
              v-if="version == 'tarif' || !version"
              :to="{ path: 'help-and-contact/call' }"
              height="45"
              max-width="220"
              :class="`mt-${version == 'tarif' ? '4' : 'auto'} text-none ft-700`"
              color="primary"
              elevation="0"
              >{{ $vuetify.lang.t('$vuetify.widget_contact.button_text_call') }}</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col
        v-if="version == 'documents'"
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        class="text-right">
        <v-btn
          :to="{ path: 'help-and-contact/call' }"
          max-width="220"
          height="45"
          class="my-3 mr-4 text-none ft-700"
          color="primary"
          elevation="0"
          >{{ $vuetify.lang.t('$vuetify.widget_contact.button_text_call') }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: ['version', 'callText'],
  data() {
    return {}
  },
}
</script>

<style scoped>
.list-item-widgetContact {
  height: 100%;
}
</style>
