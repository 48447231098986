<template>
  <v-card class="pa-3" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-card-title class="mb-1 ml-4 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_faq_referral.title') }}
    </v-card-title>
    <v-card-text>
      <!-- Show question/answer array -->
      <v-row>
        <v-col>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(item, index) in 21" :key="index">
              <v-expansion-panel-header class="ft-700-14"
                >{{ $vuetify.lang.t(`$vuetify.widget_faq_referral.question_${item}`) }}
                <template #actions>
                  <v-icon color="primary"> $expand </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content class="ft-500">
                <span
                  v-html="$vuetify.lang.t(`$vuetify.widget_faq_referral.answer_${item}`)"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      tab: 0,
    }
  },
  methods: {},
}
</script>
