/* eslint-env node */

import axios from 'axios'
import { checkAndRefreshAccessToken } from '../auth/auth'
import store from '../store/store'
import router from '../router/index'

// Portal api base configuration
const api_instance = axios.create({
  baseURL: process.env.VUE_APP_PORTAL_API,
  timeout: 60000,
})

// MBF api base configuration
const mbf_api_instance = axios.create({
  baseURL: process.env.VUE_APP_MBF_API_URL,
  timeout: 60000,
})

export async function getCustomerContract() {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  // Stop if the data is already loaded
  if (store.getters.customers_loaded) {
    return
  }
  store.commit('set_error', false)
  store.commit('set_loading', true)

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.get('/customer_contract', config)
    let customers = res.data

    store.commit('set_customers', customers)
    store.commit('set_loading', false)
  } catch (error) {
    console.log(error)
    store.commit('set_loading', false)
    router.push({ path: '/error' })
  }
}

export async function getEmailByUUID(email_uuid) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (store.getters.emails_by_uuid_loaded(email_uuid)) {
    store.commit('set_loading', false)
    return
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }

    const res = await api_instance.get(`/contract/documents/email/${email_uuid}`, config)
    // res.data = {body: '<html><body><h1>Test</h1></body></html>'}
    // console.log(res.data)
    store.commit('set_email_by_uuid', { key: email_uuid, value: res.data.body })
    store.commit('set_loading', false)
    store.commit('set_error', false)
  } catch (error) {
    store.commit('set_loading', false)
    store.commit('set_error', true)
  }
}

export async function getEmailAttachmentByUUID(uuid) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_loading', true)
  try {
    let config = {
      responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
        Accept: 'application/octet-stream',
      },
    }

    const res = await api_instance.get(`/contract/documents/attachment/${uuid}`, config)
    store.commit('set_loading', false)
    store.commit('set_error', false)
    return res.data
  } catch (error) {
    store.commit('set_loading', false)
    store.commit('set_error', true)
    return null
  }
}

export async function getDocumentsByContractId() {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
      params: {
        contract_id: store.getters.contract_selected.id,
      },
    }

    const res = await api_instance.get('/contract/documents', config)
    store.commit('set_documents', res.data)
    store.commit('set_loading', false)
    store.commit('set_error', false)
  } catch (error) {
    store.commit('set_loading', false)
    store.commit('set_error', true)
  }
}

export async function getMeterReadingsByContractId() {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }
  // Get the meter readings for every contract for every customer
  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
      params: {
        contract_id: store.getters.contract_selected.id,
      },
    }
    const res = await api_instance.get('/contract/meter_readings', config)
    store.commit('set_meter_readings', res.data)
    store.commit('set_loading', false)
  } catch (error) {
    store.commit('set_loading', false)
    store.commit('set_error', true)
  }
}

export async function getReferrals() {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  store.commit('set_referral_code', null)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    const customer_id = store.getters.customer_selected.id
    const contract_id = store.getters.contract_selected.id

    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
      params: {
        customer_id: customer_id,
        contract_id: contract_id,
      },
    }

    let data = {
      customer_id: customer_id,
    }
    const res = await api_instance.get('/voucher/scoresheet', config)
    const resCode = await api_instance.post('/voucher/friend_referral/create', data, config)

    store.commit('set_referral_code', resCode.data)
    store.commit('set_referrals', res.data)
    store.commit('set_loading', false)
  } catch (error) {
    store.commit('set_loading', false)
    store.commit('set_error', true)
  }
}

export async function postEmail(payload) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.post('/contact/email', payload, config)

    store.commit('set_loading', false)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    store.commit('set_loading', false)
    return false
  }
}

export async function postMove(payload) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.post('/contact/address_change', payload, config)

    store.commit('set_loading', false)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    store.commit('set_loading', false)
    return false
  }
}
export async function postCallback(payload) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.post('/contact/call', payload, config)

    store.commit('set_loading', false)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    store.commit('set_loading', false)
    return false
  }
}

export async function postReferralRequest(payload) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.post('/voucher/referral/create', payload, config)

    store.commit('set_loading', false)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    store.commit('set_loading', false)
    return false
  }
}

export async function validateIban(iban) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
      params: {
        iban: iban,
      },
    }

    const res = await api_instance.get('/validation/iban', config)
    store.commit('set_loading', false)
    return res.data
  } catch (error) {
    store.commit('set_loading', false)
    store.commit('set_error', true)
  }
}

export async function postMeterReading(payload) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.post('/contact/meter_reading', payload, config)

    store.commit('set_loading', false)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    store.commit('set_loading', false)
    return false
  }
}

export async function postSepaMandate(payload) {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
    }
    const res = await api_instance.post('/contact/sepa', payload, config)

    store.commit('set_loading', false)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    store.commit('set_loading', false)
    return false
  }
}

export async function changeEmail(payload) {
  try {
    const res = await api_instance.post('/contact/email_update', payload)

    if (res.status == 200) return true
    else return false
  } catch (error) {
    return false
  }
}

export async function getObisCodes() {
  // Check the access token expiry and update if necessary
  await checkAndRefreshAccessToken()
  store.commit('set_error', false)
  store.commit('set_loading', true)
  // Stop if the data is already loaded
  if (!store.getters.customers_loaded) {
    await getCustomerContract()
  }

  try {
    const contract_id = store.getters.contract_selected.id

    let config = {
      headers: {
        Authorization: `Bearer ${store.getters.access_token}`,
      },
      params: {
        contract_id: contract_id,
      },
    }

    const res = await api_instance.get('/contract/obis_codes', config)

    store.commit('set_obis_codes', res.data)
    store.commit('set_loading', false)
  } catch (error) {
    store.commit('set_obis_codes', [])
    store.commit('set_loading', false)
    store.commit('set_error', true)
  }
}

// OFFER API CALLS
export async function getOfferData(hash) {
  try {
    const res = await mbf_api_instance.get('/veganstrom/tariff_change_offer_info', {
      params: { payload: hash },
    })
    return res.data
  } catch (error) {
    return { status: 'error' }
  }
}

export async function acceptOffer(hash) {
  try {
    await mbf_api_instance.post('/veganstrom/accept_tariff_change_offer', null, {
      params: { payload: hash },
    })
    return true
  } catch (error) {
    return false
  }
}

export async function rejectOffer(hash) {
  try {
    await mbf_api_instance.post('/veganstrom/reject_tariff_change_offer', null, {
      params: { payload: hash },
    })
    return true
  } catch (error) {
    return false
  }
}

// Link based meter upload
export async function getHashedValues(hash) {
  return await mbf_api_instance.get(`/veganstrom/meter-upload?payload=${hash}`)
}

export async function postMeterUploadRequest(payload) {
  let res = await mbf_api_instance.post(
    `/veganstrom/meter-upload?payload=${payload.payload}&to_email=${payload.email}`,
    payload.body
  )

  return res
}
