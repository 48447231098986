import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

import en from '../i18n/en'
import de from '../i18n/de'

export default new Vuetify({
  lang: {
    locales: { en, de },
    current: 'de',
  },
  theme: {
    themes: {
      light: {
        primary: '#FF6666',
        secondary: '#edf2ef',
        accent: '#82C89D',
        text_area_background: '#F5F9F7',
        background_light: '#b7dbb8',
      },
    },
  },
})
