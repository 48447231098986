<template>
  <div>
    <HeaderComponent
      :title="$vuetify.lang.t('$vuetify.sepa_mandate.header_title')"
      :location="location"
      class="mb-14" />
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <WidgetSEPAUpload :data="null" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */

import HeaderComponent from '../components/HeaderComponent'
import WidgetSEPAUpload from '../components/widgets/WidgetSEPAUpload.vue'
import { getCustomerContract } from '../api/api'
import { mapState } from 'vuex'

export default {
  name: 'UploadMeterReading',
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.sepa_mandate.header_location'),
          disabled: false,
          href: '/meter-upload',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      contracts: state => state.customer_selected.contracts,
    }),
  },
  mounted() {
    getCustomerContract()
    this.$store.commit('set_drawer_selected', 8)
  },
  methods: {},
  components: { HeaderComponent, WidgetSEPAUpload },
}
</script>
