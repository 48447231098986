<template>
  <div class="text">
    <!-- Header -->
    <div
      :style="`${
        $vuetify.breakpoint.mobile ? 'height: 120px' : 'height: 95px; position: relative;'
      } width: 100%; background-color: white; z-index: 2`">
      <v-row class="width-container mx-auto mt-2 pb-6" align="center">
        <v-col style="display: contents">
          <v-img
            class="ml-10 mt-3"
            max-width="240px"
            src="../../../public/images/logo-veganstrom.svg"></v-img>
          <p style="margin-top: 38px; margin-left: 5px; font-weight: 600" class="text-caption">
            Gegen Tierleid
          </p>
        </v-col>
      </v-row>
    </div>
    <!-- Blue div -->
    <div style="width: 100%; background-image: linear-gradient(to right, #009ec1 0%, #5dc5db 100%)">
      <!-- accepted -->
      <div
        v-if="status === 'accepted'"
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        :style="`${$vuetify.breakpoint.mobile ? 'padding-top: 150px' : 'padding-top: 220px'}`">
        <v-row>
          <v-col cols="12" md="7">
            <h1
              style="
                font-size: 2.4rem;
                font-weight: 500;
                background-color: white;
                width: fit-content;
              "
              class="mb-3 px-5 py-2">
              Gratulation - Du hast den Switch gemacht!
            </h1>
            <p class="white--text mt-6" style="font-size: 1.2rem; font-family: Manrope, sans-serif">
              <span class="text" style="font-size: 1.4rem">Super Entscheidung!</span> Mit dem
              VeganStrom Clever Tarif entscheidest Du Dich für nachhaltige Energie und genießt 24
              Monate stabile Preise. Wir freuen uns sehr, diesen Weg mit Dir zu gehen!
              <br />
              <br />
              <span class="text" style="font-size: 1.4rem">Was passiert jetzt?</span>
              <br />
              In Kürze erhältst Du eine Bestätigungsmail inklusive Deiner neuen Vertragsbestätigung
              mit allen Details zu Deinem neuen Tarif.
              <br />
              <br />
              Herzliche Grüße,
              <br />
              <br />
              Dein VeganStrom-Team
            </p>
          </v-col>
          <v-col cols="12" md="5" class="pa-0" align="middle">
            <v-img
              contain
              style="z-index: 1"
              max-width="550px"
              src="../../../public/images/CoinPig.png"></v-img
          ></v-col>
        </v-row>
      </div>
      <!-- rejected -->
      <div
        v-if="status === 'rejected'"
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        :style="`${$vuetify.breakpoint.mobile ? 'padding-top: 150px' : 'padding-top: 220px'}`">
        <v-row>
          <v-col cols="12" md="7">
            <h1
              style="
                font-size: 2.4rem;
                font-weight: 500;
                background-color: white;
                width: fit-content;
              "
              class="mb-3 px-5 py-2">
              Danke für deine Entscheidung!
            </h1>
            <p class="white--text mt-6" style="font-size: 1.2rem; font-family: Manrope, sans-serif">
              <span class="text" style="font-size: 1.4rem">Schade, dass Du nicht dabei bist!</span>
              Wir haben vermerkt, dass Du Dich gegen den VeganStrom Clever Tarif entschieden hast
              und das respektieren wir natürlich. Deine Meinung und Entscheidung sind uns wichtig!
              <br />
              <br />
              <span class="text" style="font-size: 1.4rem">Du möchtest doch sparen?</span>
              <br />
              Wenn Du es Dir anders überlegst und das Angebot erneut in Betracht ziehen möchtest,
              kannst Du es einfach aus Deiner E-Mail erneut laden.
              <br />
              <br />
              Herzliche Grüße,
              <br />
              <br />
              Dein VeganStrom-Team
            </p>
          </v-col>
          <v-col cols="12" md="5" class="pa-0" align="middle">
            <v-img
              contain
              style="z-index: 1"
              max-width="550px"
              src="../../../public/images/dog.png"></v-img
          ></v-col>
        </v-row>
      </div>
      <!-- expired -->
      <div
        v-if="status === 'expired'"
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        :style="`${$vuetify.breakpoint.mobile ? 'padding-top: 150px' : 'padding-top: 220px'}`">
        <v-row>
          <v-col cols="12" md="7">
            <h1
              style="
                font-size: 2.4rem;
                font-weight: 500;
                background-color: white;
                width: fit-content;
              "
              class="mb-3 px-5 py-2">
              Dein Persönliches Angebot ist abgelaufen - Jetzt Erneuern
            </h1>
            <p class="white--text mt-6" style="font-size: 1.2rem; font-family: Manrope, sans-serif">
              Leider ist Dein persönliches Angebot bei VeganStrom bereits abgelaufen. Wir schätzen
              unsere Kunden sehr und möchten sicherstellen, dass Du mit VeganStrom rundum glücklich
              bist.
              <br />
              <br />
              <span class="text" style="font-size: 1.4rem">Bereit für ein neues Sparangebot?</span>
              Unser engagiertes Kundenservice-Team ist jederzeit bereit, Dir zu helfen und das
              perfekte Angebot für Dich zusammenzustellen.
              <br />
              <br />
              <span class="text" style="font-size: 1.4rem">So erreichst Du uns ganz einfach:</span>
              <ul>
                <li><b>Rückruf anfordern:</b> Fülle das <a style="color: unset; font-weight: bold;" href="https://www.veganstrom.com/hilfe-kontakt/" target="_blank">Formular</a> auf unserer Website aus und wir
              melden uns schnellstmöglich bei dir.</li>
                <li><b>Per E-Mail:</b> Kontaktiere uns unter
              service@veganstrom.com</li>
                <li><b>Telefonisch:</b> Unter der Nummer 030 549073272 sind wir direkt für
              dich da.</li>
              </ul>
              <br>
              Wir freuen uns darauf, Dich auch in Zukunft bei VeganStrom willkommen zu
              heißen und Dir stets das beste Angebot zu bieten. 
              <br>
              <br>
              Mit herzlichen Grüßen, 
              <br>
              <br>
              Dein VeganStrom-Team
            </p>
          </v-col>
          <v-col cols="12" md="5" class="pa-0" align="middle">
            <v-img
              contain
              style="z-index: 1"
              max-width="550px"
              src="../../../public/images/dog.png"></v-img
          ></v-col>
        </v-row>
      </div>
      <!-- error -->
      <div
        v-if="status === 'error'"
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        :style="`${$vuetify.breakpoint.mobile ? 'padding-top: 150px' : 'padding-top: 220px'}`">
        <v-row>
          <v-col cols="12" md="7">
            <h1
              style="
                font-size: 2.4rem;
                font-weight: 500;
                background-color: white;
                width: fit-content;
              "
              class="mb-3 px-5 py-2">
              Ups, da ist was schiefgelaufen!
            </h1>
            <p class="white--text mt-6" style="font-size: 1.2rem; font-family: Manrope, sans-serif">
              Leider gab es ein Problem beim Laden Deines Angebots. Wir sind bereits dabei, das Problem zu beheben und bitten Dich, es später noch einmal zu versuchen.
              <br />
              <br />
              <span class="text" style="font-size: 1.4rem">Brauchst Du sofortige Unterstützung?</span>
              Solltest Du sofortige Unterstützung benötigen, melde Dich bitte bei unserem Kundenservice. Wir stehen Dir jederzeit zur Verfügung, um sicherzustellen, dass Deine Anliegen schnell und effizient bearbeitet werden.
              <br />
              <br />
              <span class="text" style="font-size: 1.4rem">So erreichst Du uns:</span>
              <ul>
                <li><b>Rückruf anfordern:</b> Fülle das <a style="color: unset; font-weight: bold;" href="https://www.veganstrom.com/hilfe-kontakt/" target="_blank">Formular</a> auf unserer Website aus und wir
              melden uns schnellstmöglich bei dir.</li>
                <li><b>Per E-Mail:</b> Kontaktiere uns unter
              service@veganstrom.com</li>
                <li><b>Telefonisch:</b> Unter der Nummer 030 549073272 sind wir direkt für
              dich da.</li>
              </ul>
              <br>
              Mit herzlichen Grüßen, 
              <br>
              <br>
              Dein VeganStrom-Team
            </p>
          </v-col>
          <v-col cols="12" md="5" class="pa-0" align="middle">
            <v-img
              contain
              style="z-index: 1"
              max-width="550px"
              src="../../../public/images/dog.png"></v-img
          ></v-col>
        </v-row>
      </div>
    </div>
    <!-- Wave -->
    <div v-show="!$vuetify.breakpoint.smAndDown" style="width: 100%; position: relative">
      <v-row style="margin-top: 11px">
        <v-col class="pa-0" cols="6">
          <svg
            class="gradient-svg"
            viewBox="0 0 2561 500"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stop-color="#009ec1" />
                <stop offset="100%" stop-color="#2EB1CE" />
              </linearGradient>
            </defs>
            <path
              fill="rgba(47, 73, 94, 1)"
              d="M 0 108 C 475 108 475 414 950 414 L 950 414 L 950 0 L 0 0 ZM 949 414 C 1445.5 414 1445.5 73 1942 73 L 1942 73 L 1942 0 L 949 0 ZM 1941 73 C 2251 73 2251 0 2561 0 L 2561 0 L 2561 0 L 1941 0 Z"
              stroke-width="0"></path>
          </svg>
        </v-col>
      </v-row>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: 'AcceptedRejectedComponent',
  props: ['status'],
  mounted() {
    this.$vuetify.goTo(0)
  },
}
</script>

<style scoped>
.width-container {
  max-width: 1100px;
  position: relative;
}
.text {
  font-family: 'Baloo' !important;
  font-weight: 500 !important;
  line-height: 1.4;
  letter-spacing: normal;
}

.gradient-svg path {
  fill: url(#gradient);
}
</style>