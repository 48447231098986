<template>
  <v-hover>
    <template #default="{ hover }">
      <v-card
        v-if="contract_selected"
        class="pa-3"
        height="100%"
        :elevation="hover ? 6 : 1"
        rounded="lg">
        <v-card-title class="mb-3 ft-700-24">
          <v-container class="pa-0">
            <!-- anticipated_payment  -->
            <v-row dense>
              <v-col cols="5" md="12" lg="5">
                <span class="ft-500">Ab<wbr />schlags<wbr />zeit<wbr />raum </span>
              </v-col>
              <v-col
                v-if="anticipated_payments && anticipated_payments.length > 1"
                cols="7"
                md="12"
                lg="7"
                @click.stop>
                <v-select
                  v-model="anticipated_payment_selected"
                  solo
                  dense
                  :items="anticipated_payments"
                  item-text="time_period"
                  return-object></v-select>
              </v-col>
            </v-row>
            <!-- anticipated_payment name -->
            <v-row dense>
              <v-col cols="12">
                <span>
                  {{
                    anticipated_payment_selected.tariff_name
                      ? anticipated_payment_selected.tariff_name
                      : '--'
                  }}
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>
        <!-- Data -->
        <v-card-text class="ft-500">
          <v-divider class="mb-2"></v-divider>

          <!-- <v-row dense>
        <v-col class="py-0" cols="8"
          ><span
            >{{ $vuetify.lang.t("$vuetify.widget_tarif.contract_number") }}
          </span>
        </v-col>
        <v-col class="text-right py-0" cols="4"
          >{{ contract_selected.contract_number }}
        </v-col>
      </v-row> -->

          <v-row dense>
            <v-col class="py-0" cols="6">
              <span>
                {{ $vuetify.lang.t('$vuetify.widget_tarif.customer_number') }}
              </span>
            </v-col>
            <v-col class="text-right py-0" cols="6">
              {{ customer_selected.customer_number ? customer_selected.customer_number : '--' }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="py-0" cols="6">
              <span v-html="$vuetify.lang.t('$vuetify.widget_tarif.contract_term')"></span>
            </v-col>
            <v-col class="text-right py-0" cols="6">
              {{
                anticipated_payment_selected.contract_term
                  ? anticipated_payment_selected.contract_term
                  : '--'
              }}
            </v-col>
          </v-row>

          <v-row dense>
            <v-col class="py-0" cols="6">
              <span>
                {{ $vuetify.lang.t('$vuetify.widget_tarif.anticipated_payment') }}
              </span>
            </v-col>
            <v-col class="text-right py-0 mb-2" cols="6">
              {{
                anticipated_payment_selected.anticipated_payment
                  ? anticipated_payment_selected.anticipated_payment
                  : '--'
              }}
            </v-col>
          </v-row>

          <v-divider class="mt-1"></v-divider>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
      anticipated_payment_selected: state => state.anticipated_payment_selected,
      anticipated_payments: state => state.contract_selected.anticipated_payments,
    }),
    anticipated_payment_selected: {
      get: function () {
        return this.$store.getters.anticipated_payment_selected
      },
      set: function (newValue) {
        this.$store.commit('set_anticipated_payment_selected', newValue)
      },
    },
  },
}
</script>
