<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <v-card class="pa-4" height="100%" elevation="1" rounded="lg">
    <!-- Title -->
    <p class="ma-2 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_meter_upload_small.title') }}
    </p>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <v-divider v-if="$vuetify.breakpoint.smAndDown && !version" class="my-1"></v-divider>

        <v-list-item three-line class="list-item-widgetContact">
          <!-- Right icon -->
          <v-img
            class="mt-5 mb-auto ml-0 mr-4"
            src="../../../../public/images/icons/zaehlernummer.svg"
            max-width="38"></v-img>
          <v-list-item-content>
            <!-- Right text -->
            <v-card-text
              class="px-0 py-2 mb-auto"
              v-html="$vuetify.lang.t('$vuetify.widget_meter_upload_small.text')">
            </v-card-text>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" sm="12" md="4" lg="4" xl="4" class="text-right">
        <v-btn
          :to="{ path: 'meter-upload' }"
          max-width="220"
          height="45"
          class="my-3 mr-4 text-none ft-700"
          color="primary"
          elevation="0"
          >{{ $vuetify.lang.t('$vuetify.widget_meter_upload_small.button_text') }}</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>

<style scoped>
.list-item-widgetContact {
  height: 100%;
}
</style>
