<template>
  <v-card class="pa-3" elevation="1" rounded="lg" height="100%">
    <v-card-text>
      <v-row>
        <v-col>
          <!-- Tab headers -->
          <v-tabs v-model="tab" color="primary" show-arrows>
            <v-tab class="text-none ft-700 black--text">
              {{ $vuetify.lang.t('$vuetify.widget_document.document') }}
            </v-tab>
            <!-- TODO enable in the future -->
            <!-- <v-tab class="text-none ft-700 black--text">{{
              $vuetify.lang.t("$vuetify.widget_invoice.energy_account")
            }}</v-tab> -->
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- Tab 1 -->
            <v-tab-item>
              <v-divider class="mb-8"></v-divider>
              <!-- Table -->
              <v-data-table
                :headers="documents_by_contract_headers"
                :items="documents_by_contract"
                :items-per-page="5">
                <template #item="{ item }">
                  <tr class="ft-700">
                    <td class="ft-500">
                      {{ new Date(item.document_date).toLocaleDateString('de-DE') }}
                    </td>
                    <td class="ft-500">
                      {{ item.file_type == 'invoice_pdf' ? item.invoice_type : item.document_type }}
                    </td>
                    <!-- <td class="ft-500">{{ item.document_type }}</td> -->
                    <td class="ft-500 pr-9">
                      {{ item.billing_gross_amount_sum }}
                    </td>
                    <td v-show="item.file_url" class="ft-500 pr-9">
                      <a class="ft-500" :href="item.file_url" download>
                        <v-img
                          style="vertical-align: middle; cursor: pointer"
                          class="d-inline-block mr-2"
                          src="../../../public/images/google_icons/file_download_black_24dp.svg"
                          max-width="32"></v-img>
                      </a>
                    </td>
                    <td v-show="item.file_type === 'attachment_pdf'" class="ft-500 pr-9">
                      <v-img
                        style="vertical-align: middle; cursor: pointer"
                        class="d-inline-block mr-2"
                        src="../../../public/images/google_icons/file_download_black_24dp.svg"
                        max-width="32"
                        @click="
                          downloadAttachment(item.attachment_uuid, item.attachment_filename)
                        "></v-img>
                    </td>
                    <td v-show="item.file_type == 'email'" class="ft-500 pr-9">
                      <v-icon
                        style="cursor: pointer"
                        color="black"
                        @click="openEmail(item.email_uuid)"
                        >mdi-file-find</v-icon
                      >
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-dialog v-model="dialogEmail" width="80%">
                <v-card>
                  <v-card-title class="text-h5 white--text accent"> Deine E-Mail </v-card-title>

                  <v-card-text>
                    <shadow-root>
                      <div v-html="emails_by_uuid[dialogEmailUUID]"></div>
                    </shadow-root>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="
                        dialogEmail = false
                        dialogEmailUUID = null
                      ">
                      Schließen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-tab-item>
            <!-- Tab 2 -->
            <v-tab-item>
              <!-- TODO Energy account information? -->
              <v-divider class="mb-4"></v-divider>
              <p class="text-right ft-500">
                {{ $vuetify.lang.t('$vuetify.widget_document.balance_at') }}
                {{ `${today.getDate()}.${today.getMonth()}.${today.getFullYear()}` }}
                <b class="ml-2 ft-700-18">-240,00 Euro</b>
              </p>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
/* eslint-env node */

import { getDocumentsByContractId, getEmailByUUID, getEmailAttachmentByUUID } from '../../api/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      dialogEmail: false,
      dialogEmailUUID: null,
      today: new Date(),
      tab: 0,
      documents_by_contract_headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.widget_document.document_header_document_date'),
          value: 'document_date',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.widget_document.document_header_document_type'),
          value: 'document_type',
        },
        {
          text: this.$vuetify.lang.t(
            '$vuetify.widget_document.document_header_billing_gross_amount_sum'
          ),
          value: 'billing_gross_amount_sum',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.widget_document.document_header_actions'),
        },
      ],
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
      documents_by_contract: state => state.documents_by_contract,
      emails_by_uuid: state => state.emails_by_uuid,
    }),
  },
  mounted() {
    getDocumentsByContractId()
  },
  methods: {
    getImgUrl(imgUrl) {
      return require('../../../public/images/' + imgUrl)
    },
    async openEmail(emailUuid) {
      await getEmailByUUID(emailUuid)
      this.dialogEmailUUID = emailUuid
      this.dialogEmail = true
    },
    async downloadAttachment(uuid, filename) {
      const content = await getEmailAttachmentByUUID(uuid)
      const blob = new Blob([content], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      document.body.removeChild(link)
    },
  },
}
</script>

<style>
.v-data-table-header th {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #242a2a !important;
}
</style>
