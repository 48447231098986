<template>
  <v-hover>
    <template #default="{ hover }">
      <v-card id="widget-referral-card" :elevation="hover ? 6 : 1" height="100%" rounded="lg">
        <!-- <v-img
          class="rounded-corners"
          v-show="$vuetify.breakpoint.xl || $vuetify.breakpoint.smAndDown"
          height="100%"
          src="../../../../public/images/referral-dashboard.png"
        ></v-img> -->
        <v-img
          class="rounded-corners"
          height="100%"
          src="../../../../public/images/referral-dashboard-centered.png"></v-img>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style>
#widget-referral-card .v-responsive__sizer {
  padding-bottom: 43.5% !important;
}
.rounded-corners {
  border-radius: 8px;
}
</style>
