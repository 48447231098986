<template>
  <v-footer
    :color="`${$route.name == 'Referral' ? 'background_light' : 'secondary'}`"
    padless
    inset
    app
    class="container py-1 rounded">
    <v-row justify="start" no-gutters>
      <!-- Display links list -->
      <a
        v-for="link in links"
        :key="link.text"
        :href="link.link"
        text
        rounded-lg
        class="mx-2 my-1 text-decoration-none ft-500-caption">
        {{ link.text }}
      </a>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_vegan_strom'),
          link: 'https://www.veganstrom.com/',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_imprint'),
          link: 'https://www.veganstrom.com/impressum/',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_agb'),
          link: 'https://www.veganstrom.com/agb/',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_data_policy'),
          link: 'https://www.veganstrom.com/datenschutz/',
        },
      ],
    }
  },
}
</script>
