<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div>
    <HeaderComponent :location="location" class="mb-14" />
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <v-row>
          <!-- <v-col
            @click="$router.push({ path: 'my-co2-check' })"
            style="cursor: pointer"
          >
            <WidgetSimple
              :hoverable="true"
              :title="widgetSimpleDataList[0].title"
              :content="widgetSimpleDataList[0].content"
              :icon="widgetSimpleDataList[0].icon"
            />
          </v-col> -->
          <!-- <v-col
            @click="$router.push({ path: 'co2-compensation' })"
            style="cursor: pointer"
          >
            <WidgetSimple
              :hoverable="true"
              :title="widgetSimpleDataList[1].title"
              :content="widgetSimpleDataList[1].content"
              :icon="widgetSimpleDataList[1].icon"
            />
          </v-col> -->
        </v-row>
        <v-row>
          <v-col style="cursor: pointer" @click="$router.push({ path: 'documents' })">
            <WidgetSimple
              :hoverable="true"
              :title="widgetSimpleDataList[2].title"
              :content="widgetSimpleDataList[2].content"
              :icon="widgetSimpleDataList[2].icon" />
          </v-col>
          <v-col style="cursor: pointer" @click="$router.push({ path: 'metering' })">
            <WidgetSimple
              :hoverable="true"
              :title="widgetSimpleDataList[3].title"
              :content="widgetSimpleDataList[3].content"
              :icon="widgetSimpleDataList[3].icon" />
          </v-col>
        </v-row>
        <v-row>
          <v-col style="cursor: pointer" @click="$router.push({ path: 'my-data' })">
            <WidgetSimple
              :hoverable="true"
              :title="widgetSimpleDataList[4].title"
              :content="widgetSimpleDataList[4].content"
              :icon="widgetSimpleDataList[4].icon" />
          </v-col>
          <v-col style="cursor: pointer" @click="$router.push({ path: 'contract-overview' })">
            <WidgetSimple
              :hoverable="true"
              :title="widgetSimpleDataList[5].title"
              :content="widgetSimpleDataList[5].content"
              :icon="widgetSimpleDataList[5].icon" />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        style="cursor: pointer"
        @click="$router.push({ path: 'referral' })">
        <WidgetReferral />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <WidgetContact
          :call-text="$vuetify.lang.t('$vuetify.dashboard.widget_contact_call_text')" />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="4"
        lg="4"
        xl="4"
        style="cursor: pointer"
        @click="$router.push({ path: 'tarif-overview' })">
        <WidgetTarif />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="(widget, index) in widgetSimpleImageDataList"
        :key="index"
        cols-12
        sm="6"
        md="4"
        lg="4"
        xl="4">
        <WidgetSimpleImage
          :title="widget.title"
          :content="widget.content"
          :image="widget.image"
          :link="widget.link"
          :href="widget.href" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */

import { getCustomerContract } from '../api/api'
import { mapState } from 'vuex'
import HeaderComponent from '../components/HeaderComponent'
import WidgetSimple from '../components/widgets/dashboard/WidgetSimple'
import WidgetTarif from '../components/widgets/dashboard/WidgetTarif'
import WidgetReferral from '../components/widgets/dashboard/WidgetReferral'
import WidgetContact from '../components/widgets/dashboard/WidgetContact'
import WidgetSimpleImage from '../components/widgets/dashboard/WidgetSimpleImage'

export default {
  name: 'Dashboard',
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.dashboard.header_location'),
          disabled: false,
          href: '/',
        },
      ],
      widgetSimpleDataList: [
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_1_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_1_content'),
          icon: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_1_icon'),
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_2_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_2_content'),
          icon: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_2_icon'),
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_3_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_3_content'),
          icon: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_3_icon'),
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_4_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_4_content'),
          icon: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_4_icon'),
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_5_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_5_content'),
          icon: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_5_icon'),
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_6_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_6_content'),
          icon: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_6_icon'),
        },
      ],
      widgetSimpleImageDataList: [
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_1_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_1_content'),
          image: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_1_image'),
          link: 'help-and-contact/move',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_2_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_2_content'),
          image: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_2_image'),
          link: 'help-and-contact/faq',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_3_title'),
          content: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_3_content'),
          image: this.$vuetify.lang.t('$vuetify.dashboard.widget_simple_image_3_image'),
          href: 'https://www.veganstrom.com/',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contracts: state => state.customer_selected.contracts,
    }),
  },
  mounted() {
    getCustomerContract()
  },
  components: {
    WidgetSimple,
    WidgetTarif,
    WidgetReferral,
    WidgetContact,
    WidgetSimpleImage,
    HeaderComponent,
  },
}
</script>

<style scoped></style>
