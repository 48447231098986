<template>
  <div>
    <HeaderComponent
      :disable-title="true"
      :disable-selection="true"
      :location="location"
      class="mb-14" />
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <WidgetReferral />
      </v-col>
    </v-row>
    <v-row class="mt-6">
      <v-col
        v-for="(widget, index) in widgetSimpleImageDataList"
        :key="index"
        cols-12
        sm="6"
        md="4"
        lg="4"
        xl="4">
        <WidgetSimpleImage
          :title="widget.title"
          :content="widget.content"
          :image="widget.image"
          :link="widget.link"
          :href="widget.href" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable vue/multi-word-component-names */

import HeaderComponent from '../components/HeaderComponent'
import WidgetSimpleImage from '../components/widgets/dashboard/WidgetSimpleImage'
import WidgetReferral from '../components/widgets/WidgetReferral.vue'
import { getCustomerContract } from '../api/api'
import { mapState } from 'vuex'

export default {
  name: 'Referral',
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.referral.header_location'),
          disabled: false,
          href: '/referral',
        },
      ],
      widgetSimpleImageDataList: [
        {
          title: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_1_title'),
          content: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_1_content'),
          image: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_1_image'),
          link: 'help-and-contact/faq-referral',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_2_title'),
          content: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_2_content'),
          image: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_2_image'),
          href: 'https://www.peta.de/',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_3_title'),
          content: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_3_content'),
          image: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_3_image'),
          href: 'https://www.veganstrom.com/',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      contracts: state => state.customer_selected.contracts,
    }),
  },
  mounted() {
    getCustomerContract()
    this.$store.commit('set_drawer_selected', 6)
  },
  methods: {},
  components: { WidgetSimpleImage, HeaderComponent, WidgetReferral },
}
</script>
