<template>
  <v-card class="pa-3" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-card-title class="mb-1 ml-4 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_faq.title') }}
    </v-card-title>
    <v-card-text>
      <!-- Show question/answer array -->
      <v-row>
        <v-col>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(item, index) in 17" :key="index">
              <v-expansion-panel-header class="ft-700-14"
                >{{ $vuetify.lang.t(`$vuetify.widget_faq.question_${item}`) }}
                <template #actions>
                  <v-icon color="primary"> $expand </v-icon>
                </template></v-expansion-panel-header
              >
              <v-expansion-panel-content class="ft-500">
                <span v-html="$vuetify.lang.t(`$vuetify.widget_faq.answer_${item}`)"></span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <!-- <v-tabs color="primary" v-model="tab" center-active show-arrows>
            <v-tab class="ft-700 text-none black--text">{{
              $vuetify.lang.t("$vuetify.widget_faq.supply")
            }}</v-tab>
            <v-tab class="ft-700 text-none black--text">{{
              $vuetify.lang.t("$vuetify.widget_faq.payment")
            }}</v-tab>
            <v-tab class="ft-700 text-none black--text">{{
              $vuetify.lang.t("$vuetify.widget_faq.order")
            }}</v-tab>
            <v-tab class="ft-700 text-none black--text">{{
              $vuetify.lang.t("$vuetify.widget_faq.energy_change")
            }}</v-tab>
            <v-tab class="ft-700 text-none black--text">{{
              $vuetify.lang.t("$vuetify.widget_faq.customer_account")
            }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(tabObj, index) in tabContent" :key="index">
              <v-expansion-panels accordion>
                <v-expansion-panel
                  v-for="(question, indexQuestion) in tabObj.questions"
                  :key="indexQuestion"
                >
                  <v-expansion-panel-header class="ft-700-14"
                    >{{ question.question }}
                    <template v-slot:actions>
                      <v-icon color="primary"> $expand </v-icon>
                    </template></v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="ft-500">
                    {{ question.answer }}
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-tab-item>
          </v-tabs-items> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      tab: 0,
    }
  },
  methods: {},
}
</script>
