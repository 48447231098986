<template>
  <v-card class="pa-0" height="100%" elevation="1" rounded="lg">
    <!-- Image -->
    <v-img width="100%" :src="getImgUrl(`${image}.jpg`)"></v-img>
    <v-list-item three-line>
      <v-list-item-content class="px-2">
        <!-- Title -->
        <v-list-item-title class="mb-1 ft-700">{{ title }}</v-list-item-title>
        <!-- Text -->
        <v-card-text class="px-0 py-2 ft-500">{{ content }}</v-card-text>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <v-row>
        <v-col class="text-right mr-2">
          <!-- Icon arrow -->
          <v-btn v-if="link" height="45" icon :to="'/' + link">
            <v-icon color="primary">mdi-arrow-right</v-icon>
          </v-btn>
          <v-btn v-if="href" height="45" icon :href="href">
            <v-icon color="primary">mdi-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
/* eslint-env node */

export default {
  props: ['title', 'content', 'image', 'link', 'href'],
  data() {
    return {}
  },
  methods: {
    getImgUrl(imgUrl) {
      return require('../../../../public/images/' + imgUrl)
    },
  },
}
</script>
