<template>
  <div>
    <HeaderComponent :title="view.title" :location="view.location" class="mb-14" />
    <div v-if="view.type == 'overview'">
      <v-row>
        <v-col
          v-for="(widget, index) in widgetSimpleImageDataList"
          v-show="index != 3"
          :key="index"
          cols-12
          sm="6"
          md="4"
          lg="4"
          xl="4">
          <WidgetSimpleImage
            :title="widget.title"
            :content="widget.content"
            :image="widget.image"
            :link="widget.link" />
        </v-col>
        <v-col
          v-for="(widget, index) in widgetSimpleDataList"
          :key="index + '2'"
          cols-12
          sm="6"
          md="6"
          lg="6"
          xl="6">
          <WidgetSimple :hoverable="false" :title="widget.title" :content="widget.content" />
        </v-col>
      </v-row>
    </div>

    <div v-if="view.type == 'call'">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <WidgetCallBack />
        </v-col>
      </v-row>
    </div>

    <div v-if="view.type == 'email'">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <WidgetEmail />
        </v-col>
      </v-row>
    </div>

    <div v-if="view.type == 'move'">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <WidgetMove />
        </v-col>
      </v-row>
    </div>

    <div v-if="view.type == 'faq'">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <WidgetFaq />
        </v-col>
        <v-col cols="12">
          <h3 class="mt-4 ml-2 ft-700-18">
            {{ $vuetify.lang.t('$vuetify.help_and_contact.faq_question') }}
          </h3>
        </v-col>
        <v-col
          v-for="(widget, index) in widgetSimpleImageDataList"
          v-show="index != 2"
          :key="index"
          cols-12
          sm="6"
          md="4"
          lg="4"
          xl="4">
          <WidgetSimpleImage
            :title="widget.title"
            :content="widget.content"
            :image="widget.image"
            :link="widget.link" />
        </v-col>
      </v-row>
    </div>
    <div v-if="view.type == 'faq-referral'">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <WidgetFaqReferral />
        </v-col>
        <v-col cols="12">
          <h3 class="mt-4 ml-2 ft-700-18">
            {{ $vuetify.lang.t('$vuetify.help_and_contact.faq_referral_question') }}
          </h3>
        </v-col>
        <v-col
          v-for="(widget, index) in widgetSimpleImageDataList"
          v-show="index != 2"
          :key="index"
          cols-12
          sm="6"
          md="4"
          lg="4"
          xl="4">
          <WidgetSimpleImage
            :title="widget.title"
            :content="widget.content"
            :image="widget.image"
            :link="widget.link"
            :href="widget.href" />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent'
import WidgetCallBack from '../components/widgets/WidgetCallBack.vue'
import WidgetEmail from '../components/widgets/WidgetEmail.vue'
import WidgetMove from '../components/widgets/WidgetMove.vue'
import WidgetFaq from '../components/widgets/WidgetFaq.vue'
import WidgetFaqReferral from '../components/widgets/WidgetFaqReferral.vue'
import WidgetSimpleImage from '../components/widgets/dashboard/WidgetSimpleImage'
import WidgetSimple from '../components/widgets/dashboard/WidgetSimple'

export default {
  name: 'HelpAndContact',
  components: {
    HeaderComponent,
    WidgetCallBack,
    WidgetSimpleImage,
    WidgetSimple,
    WidgetEmail,
    WidgetMove,
    WidgetFaq,
    WidgetFaqReferral,
  },
  data() {
    return {
      widgetSimpleImageDataList: [
        {
          title: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_1_title'),
          content: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_1_content'),
          image: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_1_image'),
          link: 'help-and-contact/call',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_2_title'),
          content: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_2_content'),
          image: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_2_image'),
          link: 'help-and-contact/email',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_1_title'),
          content: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_1_content'),
          image: this.$vuetify.lang.t('$vuetify.referral.widget_simple_image_1_image'),
          link: 'help-and-contact/faq-referral',
        },
        {
          title: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_4_title'),
          content: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_4_content'),
          image: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_simple_image_4_image'),
          href: 'https://www.veganstrom.com/',
        },
      ],
      widgetSimpleDataList: [
        {
          title: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_data_post_title'),
          content: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_data_post_content'),
        },
        {
          title: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_data_email_title'),
          content: this.$vuetify.lang.t('$vuetify.help_and_contact.widget_data_email_content'),
        },
      ],
    }
  },
  computed: {
    // Change the view based on the route parameter type
    view: function () {
      let viewTmp = {}
      switch (this.$route.params.type) {
        case 'overview':
          viewTmp.title = this.$vuetify.lang.t('$vuetify.help_and_contact.header_title_overview')
          viewTmp.location = [
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_base'),
              disabled: false,
              href: '/help-and-contact/overview',
            },
          ]
          break
        case 'faq-referral':
          viewTmp.title = this.$vuetify.lang.t(
            '$vuetify.help_and_contact.header_title_faq_referral'
          )
          viewTmp.location = [
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_base'),
              disabled: false,
              href: '/help-and-contact/overview',
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_faq_referral'),
              disabled: false,
              href: '/help-and-contact/faq-referral',
            },
          ]
          break
        case 'faq':
          viewTmp.title = this.$vuetify.lang.t('$vuetify.help_and_contact.header_title_faq')
          viewTmp.location = [
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_base'),
              disabled: false,
              href: '/help-and-contact/overview',
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_faq'),
              disabled: false,
              href: '/help-and-contact/faq',
            },
          ]
          break
        case 'call':
          viewTmp.title = this.$vuetify.lang.t('$vuetify.help_and_contact.header_title_call')
          viewTmp.location = [
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_base'),
              disabled: false,
              href: '/help-and-contact/overview',
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_call'),
              disabled: false,
              href: '/help-and-contact/call',
            },
          ]
          break
        case 'email':
          viewTmp.title = this.$vuetify.lang.t('$vuetify.help_and_contact.header_title_email')
          viewTmp.location = [
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_base'),
              disabled: false,
              href: '/help-and-contact/overview',
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_email'),
              disabled: false,
              href: '/help-and-contact/email',
            },
          ]
          break
        case 'move':
          viewTmp.title = this.$vuetify.lang.t('$vuetify.help_and_contact.header_title_move')
          viewTmp.location = [
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_base'),
              disabled: false,
              href: '/help-and-contact/overview',
            },
            {
              text: this.$vuetify.lang.t('$vuetify.help_and_contact.header_location_move'),
              disabled: false,
              href: '/help-and-contact/move',
            },
          ]
          break
        default:
          break
      }
      return {
        type: this.$route.params.type,
        title: viewTmp.title,
        location: viewTmp.location,
      }
    },
    title: function () {
      return this.$route.params.type
    },
  },
  mounted() {
    this.$store.commit('set_drawer_selected', 7)
  },
  methods: {},
}
</script>
