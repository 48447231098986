<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div>
    <v-container grid-list-xs>
      <v-row>
        <v-col>
          <v-img class="rounded-lg" src="../../public/images/maintenance.png"></v-img>
        </v-col>
      </v-row>
    </v-container>
    <p></p>
  </div>
</template>

<script>
export default {
  methods: {},
}
</script>

<style></style>
