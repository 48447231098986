/* eslint-env node */

import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '../views/Dashboard.vue'
import ErrorPage from '../views/ErrorPage.vue'
import Logout from '../views/Logout.vue'
import MyData from '../views/MyData.vue'
import ContractOverview from '../views/ContractOverview.vue'
import TarifOverview from '../views/TarifOverview.vue'
import Documents from '../views/Documents.vue'
import Metering from '../views/Metering.vue'
import Referral from '../views/Referral.vue'
import Newsletter from '../views/Newsletter.vue'
import HelpAndContact from '../views/HelpAndContact.vue'
import UploadMeterReading from '../views/UploadMeterReading.vue'
import LinkBasedMeterUpload from '../views/LinkBasedMeterUpload.vue'
import SEPAMandate from '../views/SEPAMandate.vue'
import Registration from '../views/Registration.vue'
import Maintenance from '../views/Maintenance.vue'
import TarifSwitch from '../views/TarifSwitch.vue'
import store from '../store/store.js'
// import CO2Calculator from "../views/CO2Calculator.vue";

import { getInitialAuthUrl } from '../auth/auth'

Vue.use(VueRouter)

const routes = [
  {
    path: '/callback',
    name: 'Callback',
    component: () => import(/* webpackChunkName: "Callback" */ '../views/Callback.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/error',
    name: 'Error',
    component: ErrorPage,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/my-data',
    name: 'MyData',
    component: MyData,
  },
  {
    path: '/contract-overview',
    name: 'ContractOverview',
    component: ContractOverview,
  },
  {
    path: '/tarif-overview',
    name: 'TarifOverview',
    component: TarifOverview,
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
  },
  {
    path: '/metering',
    name: 'Metering',
    component: Metering,
  },
  {
    path: '/referral',
    name: 'Referral',
    component: Referral,
  },
  {
    path: '/newsletter',
    name: 'Newsletter',
    component: Newsletter,
  },
  {
    path: '/help-and-contact/:type',
    name: 'HelpAndContact',
    component: HelpAndContact,
  },
  // {
  //   path: "/my-co2-check",
  //   name: "CO2Calculator",
  //   component: CO2Calculator,
  // },
  {
    path: '/meter-upload',
    name: 'UploadMeterReading',
    component: UploadMeterReading,
  },

  {
    path: '/SEPA-mandate',
    name: 'SEPAMandate',
    component: SEPAMandate,
  },
  {
    path: '/registration/:customer/:check24Id',
    name: 'Registration',
    component: Registration,
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: Maintenance,
  },
  {
    path: '/tarif-switch',
    name: 'TarifSwitch',
    component: TarifSwitch,
  },
  {
    path: '/upload-meter-readings',
    name: 'LinkBasedMeterUpload',
    component: LinkBasedMeterUpload,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

// Route Guard
router.beforeEach((to, _from, next) => {
  // -----DEBUG---------------------
  // if (!to.query.debug && !["Maintenance", "Callback"].includes(to.name)) {
  //   store.commit("set_loading", false);
  //   router.push({
  //     name: "Maintenance",
  //   });
  //   next();
  //   return;
  // }
  // if (to.name == "Maintenance") {
  //   store.commit("set_loading", false);
  //   next();
  //   return;
  // }
  // ------------------------
  if (['/recovery'].includes(to.path)) {
    window.location =
      'https://auth.gsenergy.io/auth/recovery?return_to=https://portal.veganstrom.com'
  }

  // Redirect to login app when user is not loggedIn and accesses protected routes
  else if (
    !['Callback', 'Registration', 'TarifSwitch', 'LinkBasedMeterUpload'].includes(to.name) &&
    !store.getters.loggedIn
  ) {
    // Get the initial Oauth 2.0 url to initialize the authorization and authentication
    let authUrl = getInitialAuthUrl(to)
    // Reset loaded values to ensure updated customer data etc.
    store.commit('reset_loaded_values')
    window.location = authUrl
  }
  // Set drawer selected for navigation bar
  setDrawer(to.name)
  next()
})

export default router

// Helper function

function setDrawer(route) {
  switch (route) {
    case 'Dashboard':
      store.commit('set_drawer_selected', 0)
      break
    case 'MyData':
      store.commit('set_drawer_selected', 1)
      break
    case 'ContractOverview':
      store.commit('set_drawer_selected', 2)
      break
    case 'TarifOverview':
      store.commit('set_drawer_selected', 3)
      break
    case 'Documents':
      store.commit('set_drawer_selected', 4)
      break
    case 'Metering':
      store.commit('set_drawer_selected', 5)
      break
    case 'Referral':
      store.commit('set_drawer_selected', 6)
      break
    case 'HelpAndContact':
      store.commit('set_drawer_selected', 7)
      break

    default:
      break
  }
}
