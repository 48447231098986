<!-- eslint-disable vue/no-v-text-v-html-on-component -->

<template>
  <!-- <v-card
    style="margin: 50px;"
    id="referral-bg"
    :class="
      `pa-${$vuetify.breakpoint.mdAndUp ? 16 : $vuetify.breakpoint.xs ? 0 : 2}`
    "
    elevation="1"
    rounded="lg"
    height="100%"
  >
    <v-card-text
      :class="
        `pa-${
          $vuetify.breakpoint.mdAndUp ? 2 : $vuetify.breakpoint.xs ? 0 : 10
        } mt-4`
      "
    > -->
  <v-row
    :class="`${$vuetify.breakpoint.smAndUp ? 'pa-4 mx-10' : ''}`"
    style="background-color: white; border-radius: 10px">
    <v-col class="pa-0">
      <v-card class="pa-0 mt-0" elevation="0" rounded="lg" height="100%">
        <div v-if="referral_code" :class="`${$vuetify.breakpoint.smAndUp ? 'px-6 pt-6' : 'pa-0'}`">
          <v-img
            v-show="!$vuetify.breakpoint.smAndDown"
            style="border-radius: 5px"
            width="100%"
            src="../../../public/images/referralKids.png"></v-img>
          <v-img
            v-show="$vuetify.breakpoint.smAndDown"
            style="border-radius: 5px"
            width="100%"
            src="../../../public/images/referralKidsMobile.png"></v-img>
        </div>
        <v-card-text v-if="referral_code" class="pa-8 ft-500">
          <v-row dense>
            <v-col cols="12" lg="12">
              <!-- Headline  -->
              <!-- <h1
                    class="mb-8 font-headline"
                    v-html="$vuetify.lang.t('$vuetify.widget_referral.title')"
                  ></h1> -->
              <!-- Text -->
              <!-- <p
                    class="my-2 mb-4 font-subheadline"
                    v-html="$vuetify.lang.t('$vuetify.widget_referral.text')"
                  ></p> -->

              <v-btn-toggle v-model="sendType" mandatory class="my-4">
                <v-btn class="py-2">
                  <v-img width="30" src="../../../public/images/icons/email2.svg"></v-img>
                </v-btn>

                <v-btn>
                  <v-img width="30" src="../../../public/images/icons/whatsapp.png"></v-img>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="mb-4">
              <b class="font-subheadline">Dein Empfehlungslink:</b>
              <br />
              <b class="font-subheadline">
                <b id="referralLink" class="accent--text">
                  www.veganstrom.com/?vouchercode={{ referral_code }}
                </b>
                <v-icon
                  color="black"
                  style="cursor: pointer"
                  @click="copy('referralLink')"
                  class="mx-2">
                  mdi-content-copy
                </v-icon>
                <v-icon
                  v-if="$vuetify.breakpoint.mobile"
                  color="black"
                  style="cursor: pointer"
                  @click="share">
                  mdi-share-variant
                </v-icon>
              </b>
            </v-col>
          </v-row>
          <!-- Form -->
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- Row 1 -->
            <v-row v-if="sendType == 0" dense>
              <v-col cols="12" md="6" lg="6" :class="$vuetify.breakpoint.mobile ? '' : 'pr-3'">
                <v-text-field
                  v-model="nameFriend"
                  :rules="fieldRules"
                  :label="$vuetify.lang.t('$vuetify.widget_referral.name_friend')"
                  required
                  outlined
                  color="blue"
                  background-color="text_area_background"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="6" :class="$vuetify.breakpoint.mobile ? '' : 'pl-3'">
                <v-text-field
                  v-model="emailFriend"
                  :rules="fieldRules"
                  :label="$vuetify.lang.t('$vuetify.widget_referral.email_friend')"
                  required
                  outlined
                  color="blue"
                  background-color="text_area_background"></v-text-field>
              </v-col>
            </v-row>
            <!-- Row 2 and 3 -->
            <v-row v-if="sendType == 0" dense>
              <v-col cols="12" lg="12">
                <v-text-field
                  v-model="subject"
                  disabled
                  :rules="fieldRules"
                  :label="$vuetify.lang.t('$vuetify.widget_referral.subject')"
                  required
                  outlined
                  color="blue"
                  background-color="text_area_background"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" lg="12">
                <v-textarea
                  v-model="message"
                  outlined
                  :label="$vuetify.lang.t('$vuetify.widget_referral.message')"
                  :counter="500"
                  color="blue"
                  background-color="text_area_background"></v-textarea>
              </v-col>
            </v-row>
            <!-- Send Button -->
            <v-row class="mb-0">
              <v-col class="py-0" align="end">
                <v-btn
                  height="45"
                  dark
                  :disabled="!valid"
                  color="accent"
                  class="text-none ft-700"
                  @click="validate"
                  v-html="
                    sendType == 0
                      ? $vuetify.lang.t('$vuetify.widget_referral.button_text_send')
                      : $vuetify.lang.t('$vuetify.widget_referral.button_text_send_wa')
                  "></v-btn>
              </v-col>
            </v-row>
            <!-- Alert -->
            <v-row>
              <v-col cols="12" md="8" lg="6">
                <v-alert
                  v-show="showAlert"
                  :color="alertType == 'error' ? 'error' : 'accent'"
                  dense
                  :type="alertType">
                  {{ alertMessage }}
                </v-alert>
              </v-col>
            </v-row>
            <!-- Icons -->
            <v-progress-linear
              rounded
              height="30"
              :buffer-value="((referrals.length % 5) / 5) * 100"
              :value="((referrals.length % 5) / 5) * 100"
              stream
              color="accent">
              <template #default="{}">
                <b class="black--text" style="font-size: large">
                  {{ referrals.length % 5 }} von 5
                </b>
              </template>
            </v-progress-linear>
            <!-- <v-row justify="space-between" class="mt-12 mb-6">
                  <v-col
                    cols="2"
                    v-for="index in referrals.length % 5"
                    :key="index + 'green'"
                  >
                    <img
                      width="100%"
                      style="max-width: 90px;"
                      src="../../../public/images/vg-icon-green.png"
                    />
                  </v-col>
                  <v-col
                    cols="2"
                    v-for="index in 5 - (referrals.length % 5)"
                    :key="index + 'black'"
                  >
                    <img
                      width="100%"
                      style="max-width: 90px;"
                      src="../../../public/images/vg-icon-black.png"
                    />
                  </v-col>
                </v-row> -->
            <div class="mt-4">
              <span
                v-if="referrals.length > 0"
                class="font-subheadline accent--text"
                v-html="
                  $vuetify.lang
                    .t('$vuetify.widget_referral.referral_amount_text')
                    .replace('{referralAmount}', referrals.length)
                    .replace('{referralAmountLeft}', 5 - (referrals.length % 5))
                "></span>
              <p
                class="mb-8 font-subheadline"
                v-html="
                  $vuetify.lang
                    .t('$vuetify.widget_referral.referral_left_text')
                    .replace('{referralAmount}', referrals.length)
                    .replace('{referralAmountLeft}', 5 - (referrals.length % 5))
                "></p>
            </div>
            <!-- Image and text -->
            <v-row align="center">
              <v-col cols="12" md="6">
                <v-img
                  width="100%"
                  style="border-radius: 5px"
                  src="../../../public/images/referral-widget-crop-long.png"></v-img>
              </v-col>
              <v-col cols="12" md="6" class="pl-10">
                <p style="font-family: Baloo; font-size: 25px">
                  Geteilte Freude ist
                  <nobr>doppelte Freude</nobr>
                </p>
                <ol>
                  <li class="mb-3">
                    Versende den oben angezeigten Empfehlungslink per Mail oder ganze einfach über
                    WhatsApp.
                  </li>
                  <li class="mb-3">
                    Dein*e Freund*in schließt einen Stromvertrag bei VeganStrom ab.
                  </li>
                  <li>
                    Sobald dein*e Freund*in den ersten Abschlag bezahlt hat, bekommt jeder von euch
                    25.- Euro gutgeschrieben.
                  </li>
                </ol>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-text class="ft-500" v-if="!referral_code && !loading">
          <h2 class="mb-8 font-headline">Leider gab es ein Problem :(</h2>

          <p>
            Stelle sicher, dass du einen Aktiven Vertrag ausgewählt hast, falls du mehrere Verträge
            besitzen. Tritt das Problem weiter auf, wende dich bitte an den Kundenservice.
          </p>
          <p>Dein VeganStrom Team</p>
        </v-card-text>
        <v-card-text v-if="!referral_code && loading" class="ft-500">
          <h2 class="mb-8 font-headline">
            Nur noch einen Moment, deine Daten werden geladen<br />...
          </h2>
        </v-card-text>
      </v-card>
      <!-- Referral Table -->
    </v-col>
  </v-row>
  <!-- </v-card-text>
  </v-card> -->
</template>

<script>
import { mapState } from 'vuex'
import { postReferralRequest } from '../../api/api'
import { getReferrals } from '../../api/api'

export default {
  data() {
    return {
      tab: 0,
      valid: true,
      subject: '',
      nameFriend: '',
      emailFriend: '',
      fieldRules: [v => !!v || this.$vuetify.lang.t('$vuetify.widget_referral.text_required')],
      radioGroup: 1,
      radioOptions: [
        this.$vuetify.lang.t('$vuetify.widget_referral.bonus_1'),
        // this.$vuetify.lang.t("$vuetify.widget_referral.bonus_2"),
        // this.$vuetify.lang.t("$vuetify.widget_referral.bonus_3"),
      ],
      message: '',
      messageRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_referral.text_required'),
        v =>
          (v && v.length <= 300) ||
          this.$vuetify.lang.t('$vuetify.widget_referral.text_max_letters'),
      ],
      showAlert: false,
      alertType: 'success',
      alertMessage: 'Anfrage erfolgreich verschickt!',
      sendType: 0,
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
      referrals: state => state.referrals,
      referral_code: state => state.referral_code,
      loading: state => state.loading,
    }),
    iconSize: function () {
      if (this.$vuetify.breakpoint.xl) return 120
      if (this.$vuetify.breakpoint.lg) return 80
      if (this.$vuetify.breakpoint.md) return 50
      if (this.$vuetify.breakpoint.sm) return 50
      return 50
    },
  },
  mounted() {
    getReferrals()
      .then(() => {
        // Set initial data
        this.subject = this.customer_selected.name
          ? this.customer_selected.name +
            ' möchte dir VeganStrom empfehlen. Sicher dir jetzt 25 Euro Neukundenrabatt.'
          : this.customer_selected.first_name +
            ' ' +
            this.customer_selected.last_name +
            ' möchte dir VeganStrom empfehlen. Sicher dir jetzt 25 Euro Neukundenrabatt.'
        this.message = `Ich weiß, dass Dir Tiere genauso am Herzen liegen wie mir. Deshalb wechsle auch Du zu VeganStrom 💚🦦. VeganStrom ist nicht nur ein Ökostrom-Anbieter mit fairen Preisen, er bietet Dir auch den tierfreundlichsten Strommix. Mit dem Code: www.veganstrom.com/?vouchercode=${this.referral_code} erhalten wir beide 25.- Euro Rabatt auf unsere Stromrechnung 🙏. Lass uns gemeinsam Tiere und Umwelt schützen!`
      })
      .catch(() => {
        this.showAlert = true
        this.alertType = 'error'
        this.alertMessage = 'Es gab leider ein Problem beim erstellen deines Gutschein Codes.'
      })
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        // Create payload
        switch (this.sendType) {
          case 0: {
            let payload = {
              customer_id: this.customer_selected.id,
              contract_id: this.contract_selected.id,
              name_friend: this.nameFriend,
              email_friend: this.emailFriend,
              subject: this.subject,
              message: this.message,
              voucher: this.referral_code,
              voucher_type: this.radioGroup == 1 ? 'fifty_fifty' : 'hundred_zero',
              customer_name: this.customer_selected.name
                ? this.customer_selected.name
                : this.customer_selected.first_name + ' ' + this.customer_selected.last_name,
            }
            // Call API
            postReferralRequest(payload).then(res => {
              // Set corresponding alert
              if (res) {
                this.showAlert = true
                this.alertType = 'success'
                this.alertMessage = 'Anfrage erfolgreich verschickt!'
              } else {
                this.showAlert = true
                this.alertType = 'error'
                this.alertMessage = 'Es gab ein Problem beim verschicken der Nachricht.'
              }
            })
            break
          }
          case 1: {
            let noEmoji = this.message.replace(
              /([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF]|💚|🦦|🙏)/g,
              ''
            )
            window.open('https://wa.me/?text=' + noEmoji, '_blank')
            break
          }
          default:
            break
        }
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
    share() {
      if (navigator.share) {
        navigator.share({
          title: 'Dein VeganStrom Rabattcode',
          url: 'https://www.veganstrom.com/?vouchercode=' + this.referral_code,
        })
      } else {
        alert('Direktes teilen leider nicht unterstützt von diesem Browser.')
      }
    },
    copy(id) {
      // Get the text field
      var copyText = document.getElementById(id).textContent
      // Copy the text inside the text field
      navigator.clipboard.writeText(copyText)
      alert('Link in die Zwischenablage kopiert!')
    },
  },
}
</script>

<style lang="scss">
#referral-bg {
  background-image: url('../../../public/images/referral_bg.png'); //WORKED!
  background-size: cover;
}
</style>
