<template>
  <v-card class="pa-4" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-alert v-show="inactiveContract" class="mt-2" color="error" dense type="error">
      Der ausgewählte Vertrag ist nicht aktiv oder befindet sich nicht in der Belieferung! <br />
      Bitte wähle einen anderen Vertrag aus um das SEPA-Mandat zu erstellen.
    </v-alert>
    <v-card-title class="mb-1 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_sepa_mandate.title') }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" lazy-validation>
        <!-- Row 1 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="account_owner"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_sepa_mandate.account_owner')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="email"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_sepa_mandate.email')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 2 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="iban"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_sepa_mandate.iban')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
            <span v-if="!ibanIsValid" class="red--text text-caption">
              Für diese IBAN konnte keine BIC und Bank gefunden werden!
            </span>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="bic"
              disabled
              :label="$vuetify.lang.t('$vuetify.widget_sepa_mandate.bic')"
              outlined
              color="blue"
              background-color="text_area_background"
              required
              :rules="fieldRules"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 3 -->
        <v-row dense>
          <v-col cols="12" md="12" lg="8" class="pr-3">
            <v-text-field
              v-model="bank"
              disabled
              :label="$vuetify.lang.t('$vuetify.widget_sepa_mandate.bank')"
              outlined
              color="blue"
              background-color="text_area_background"
              required
              :rules="fieldRules"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 4 -->
        <v-row dense>
          <v-col cols="12" md="12" lg="8">
            <v-checkbox
              v-model="mandate_accept"
              class="pt-1 mt-0"
              required
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_sepa_mandate.mandate_accept')"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-btn
              :disabled="inactiveContract"
              height="50px"
              color="accent"
              class="mr-4 mb-1 text-none ft-700"
              @click="validate">
              {{ $vuetify.lang.t('$vuetify.widget_sepa_mandate.button_text_send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <!-- Alert -->
      <v-row>
        <v-col cols="12" md="8" lg="6">
          <v-alert
            v-show="showAlert"
            class="mt-2"
            :color="alertType == 'error' ? 'error' : 'accent'"
            dense
            :type="alertType">
            {{ alertMessage }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="8">
          <p v-html="$vuetify.lang.t('$vuetify.widget_sepa_mandate.mandate_document_text')"></p>
          <a
            target="_blank"
            href="https://s3.eu-central-1.amazonaws.com/gse.cdn.s3.gsenergy.io/multi-brand-framework/veganstrom/documents/SEPA-Lastschriftmandat.pdf">
            {{ $vuetify.lang.t('$vuetify.widget_sepa_mandate.sepa_mandate') }}
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" lg="8">
          <p class="text-caption mt-4">
            {{ $vuetify.lang.t('$vuetify.widget_sepa_mandate.mandate_note') }}
          </p>
          <p class="text-caption mt-2">*Pflichtangaben</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { postSepaMandate, validateIban } from '../../api/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      account_owner: '',
      name: '',
      email: '',
      iban: '',
      bic: '',
      bank: '',
      mandate_accept: '',
      fieldRules: [v => !!v || this.$vuetify.lang.t('$vuetify.widget_sepa_mandate.text_required')],
      showAlert: false,
      alertType: 'success',
      alertMessage: this.$vuetify.lang.t('$vuetify.widget_sepa_mandate.alert_success'),
      ibanIsValid: true,
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
      anticipated_payment_selected: state => state.anticipated_payment_selected,
    }),
    inactiveContract() {
      return !(
        this.contract_selected.status == 'active' || this.contract_selected.is_in_supply == 'True'
      )
    },
  },

  watch: {
    iban() {
      this.iban = this.iban.replace(' ', '')
      this.ibanIsValid = true
      if (this.iban.length < 22) return
      validateIban(this.iban)
        .then(res => {
          if (res.is_valid) {
            this.bic = res.bic
            this.bank = res.bank_name
          } else {
            this.bic = ''
            this.bank = ''
            this.ibanIsValid = false
          }
        })
        .catch(err => {
          console.log(err)
          this.ibanIsValid = false
        })
    },
  },
  mounted() {
    // Set initial data
    this.account_owner = this.customer_selected.name
    this.name = this.customer_selected.name
    this.email = this.customer_selected.email
  },
  methods: {
    validate() {
      this.showAlert = false
      if (this.$refs.form.validate()) {
        // Create payload
        let payload = {
          contract_number: this.contract_selected.contract_number,
          contract_id: this.contract_selected.id,
          account_owner: this.account_owner,
          name: this.name,
          email: this.email,
          iban: this.iban,
          bic: this.bic,
          bank_name: this.bank,
          anticipated_payment: this.anticipated_payment_selected.anticipated_payment,
        }
        // Call API
        postSepaMandate(payload).then(res => {
          // Show corresponding Alert
          if (res) {
            this.showAlert = true
            this.alertType = 'success'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_sepa_mandate.alert_success'
            )
          } else {
            this.showAlert = true
            this.alertType = 'error'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_sepa_mandate.alert_error'
            )
          }
        })
      }
    },
  },
}
</script>

<style scoped>
.hidden-sm-and-down .v-icon {
  color: white !important;
}
</style>
