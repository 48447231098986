<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div style="background-color: #edf2ef; height: 100vh">
    <v-row justify="center" class="mt-16">
      <v-col cols="12" sm="10" md="9" lg="8" xl="6" class="px-1">
        <v-card
          id="referral-bg"
          elevation="1"
          rounded="lg"
          :style="`${$vuetify.breakpoint.mobile ? 'padding: 5%;' : 'padding: 15%;'} `"
          height="100%">
          <v-card class="pa-6" elevation="1" rounded="lg" height="100%">
            <v-img class="mb-8" src="../../public/images/vegan_strom.png" max-width="194"></v-img>
            <!-- Title -->
            <v-card-title class="mb-1 mt-4 ft-700">
              {{
                $vuetify.lang.t('$vuetify.registration.title') + ' ' + $route.params.customer + ','
              }}
            </v-card-title>
            <v-card-text>
              <v-form ref="form" lazy-validation>
                <v-row dense>
                  <v-col cols="12" md="12" lg="12" xl="12">
                    <p class="ft-700-14 black--text mb-6">
                      {{ $vuetify.lang.t('$vuetify.registration.text') }}
                    </p>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="email"
                      :rules="emailRules"
                      :label="$vuetify.lang.t('$vuetify.registration.email')"
                      required
                      outlined
                      color="blue"
                      background-color="text_area_background"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12" lg="12" xl="12">
                    <v-text-field
                      v-model="email_confirm"
                      :rules="emailConfirmRules"
                      :label="$vuetify.lang.t('$vuetify.registration.email_confirm')"
                      required
                      outlined
                      color="blue"
                      background-color="text_area_background"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" lg="4">
                    <v-btn
                      height="50px"
                      color="accent"
                      class="mr-4 mb-1 text-none ft-700"
                      @click="validate">
                      {{ $vuetify.lang.t('$vuetify.registration.button_text_send') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
              <!-- Alert -->
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-alert
                    v-show="showAlert"
                    class="mt-2"
                    :color="alertType == 'error' ? 'error' : 'accent'"
                    dense
                    :type="alertType"
                    >{{ alertMessage }}</v-alert
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <a
                    v-for="link in links"
                    :key="link.text"
                    :href="link.link"
                    text
                    rounded-lg
                    class="mx-2 text-decoration-none ft-500-caption">
                    {{ link.text }}
                  </a>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { changeEmail } from '../api/api'

export default {
  data() {
    return {
      email: '',
      email_confirm: '',
      showAlert: false,
      alertType: 'success',
      alertMessage: this.$vuetify.lang.t('$vuetify.registration.alert_success'),
      emailRules: [
        value => {
          if (!value) return this.$vuetify.lang.t('$vuetify.registration.text_required')
          return true
        },
      ],
      emailConfirmRules: [
        value => {
          if (!value) return this.$vuetify.lang.t('$vuetify.registration.text_required')

          if (value != this.email)
            return this.$vuetify.lang.t('$vuetify.registration.text_email_same')
          return true
        },
      ],
      links: [
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_vegan_strom'),
          link: 'https://www.veganstrom.com/',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_imprint'),
          link: 'https://www.veganstrom.com/impressum/',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_agb'),
          link: 'https://www.veganstrom.com/agb/',
        },
        {
          text: this.$vuetify.lang.t('$vuetify.footer.link_data_policy'),
          link: 'https://www.veganstrom.com/datenschutz/',
        },
      ],
    }
  },
  methods: {
    validate() {
      this.showAlert = false
      if (this.$refs.form.validate()) {
        let payload = {
          email: this.email,
          c24_id: this.$route.params.check24Id,
        }
        changeEmail(payload)
          .then(res => {
            if (res) {
              this.alertMessage = this.$vuetify.lang.t('$vuetify.registration.alert_success')
              this.alertType = 'success'
              this.showAlert = true
            } else {
              this.alertMessage = this.$vuetify.lang.t('$vuetify.registration.alert_error')
              this.alertType = 'error'
              this.showAlert = true
            }
          })
          .catch(error => {
            console.log(error)
            this.alertMessage = this.$vuetify.lang.t('$vuetify.registration.alert_error')
            this.alertType = 'error'
            this.showAlert = true
          })
      }
    },
  },
}
</script>

<style lang="scss">
#referral-bg {
  background-image: url('../../public/images/referral_bg.png'); //WORKED!
  background-size: cover;
}
</style>
