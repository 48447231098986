import Vuex from 'vuex'
import Vue from 'vue'
import VuexPersistence from 'vuex-persist'
import { getInitialAuthUrl, getLogoutAuthUrl } from '../auth/auth'
import router from '../router/index'
import { getDocumentsByContractId, getMeterReadingsByContractId } from '../api/api'

// Make the state persist in the cookies
const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
})

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loggedIn: false,
    loading: false,
    error: null,
    drawer_selected: 0,
    code_verifier: '',
    access_token: '',
    refresh_token: '',
    expires_in: '',
    id_token: '',
    redirect_data: '',
    expiration_time: '',
    customers_loaded: false,
    referrals_loaded: false,
    customers: [],
    customer_selected: {},
    contract_selected: {},
    anticipated_payment_selected: {},
    active_anticipated_payment: null,
    documents_by_contract: [],
    meter_readings_by_contract: [],
    referrals: [],
    referral_code: null,
    obis_codes: [],
    emails_by_uuid: {},
  },
  getters: {
    loggedIn: state => {
      return state.loggedIn
    },
    loading: state => {
      return state.loading
    },
    code_verifier: state => {
      return state.code_verifier
    },
    customers: state => {
      return state.customers
    },
    access_token: state => {
      return state.access_token
    },
    refresh_token: state => {
      return state.refresh_token
    },
    customers_loaded: state => {
      return state.customers_loaded
    },
    emails_by_uuid_loaded: state => uuid => state.emails_by_uuid[uuid] !== undefined,
    referrals_loaded: state => {
      return state.referrals_loaded
    },
    contract_selected: state => {
      return state.contract_selected
    },
    customer_selected: state => {
      return state.customer_selected
    },
    anticipated_payment_selected: state => {
      return state.anticipated_payment_selected
    },
    active_anticipated_payment: state => {
      if (state.contract_selected.anticipated_payments.length > 1) {
        return state.contract_selected.anticipated_payments.find(ap => ap.active)
      }
      if (state.contract_selected.anticipated_payments.length == 1) {
        return state.contract_selected.anticipated_payments[0]
      }
      return null
    },
    redirect_data: state => {
      return state.redirect_data
    },
  },
  mutations: {
    set_loading(state, payload) {
      state.loading = payload
    },
    set_error(state, payload) {
      if (!state.loggedIn) {
        // Get the initial Oauth 2.0 url to initialize the authorization and authentication
        let authUrl = getInitialAuthUrl()
        // Reset loaded values to ensure updated customer data etc.
        store.commit('reset_loaded_values')
        window.location = authUrl
      }
      state.error = payload
    },
    set_drawer_selected(state, payload) {
      state.drawer_selected = payload
    },
    set_code_verifier(state, payload) {
      state.code_verifier = payload
    },
    set_user_data(state, payload) {
      state.access_token = payload.access_token
      state.refresh_token = payload.refresh_token
      state.expires_in = payload.expires_in
      state.id_token = payload.id_token
      state.loggedIn = true
      // Set the expiration datetime of the access token
      let exp_date = new Date()
      exp_date.setSeconds(exp_date.getSeconds() + payload.expires_in)
      state.expiration_time = exp_date
    },
    set_redirect_data(state, payload) {
      state.redirect_data = payload
    },
    refresh_access_token(state, payload) {
      state.access_token = payload.access_token
      state.refresh_token = payload.refresh_token
      state.expires_in = payload.expires_in
      state.id_token = payload.id_token
      // Set the expiration datetime of the new access token
      let exp_date = new Date()
      exp_date.setSeconds(exp_date.getSeconds() + payload.expires_in)
      state.expiration_time = exp_date
    },
    logout(state) {
      let logoutUrl = getLogoutAuthUrl()
      state.access_token = ''
      state.refresh_token = ''
      state.expires_in = ''
      state.id_token = ''
      state.loggedIn = false
      state.expiration_time = ''

      state.customer_selected = {}
      state.contracts = []
      state.contract_selected = {}
      state.documents_by_contract = []
      state.meter_readings_by_contract = []
      state.referrals = []

      state.customers_loaded = false
      state.referrals_loaded = false
      window.location = logoutUrl
    },
    set_customers(state, payload) {
      state.customers = payload
      store.commit('set_customer_selected', payload[0])
      state.customers_loaded = true
    },
    set_customer_selected(state, payload) {
      state.customer_selected = payload
      store.commit('set_contract_selected', payload.contracts[0])
    },
    set_contract_selected(state, payload) {
      state.contract_selected = payload
      let activeIndex = 0
      for (let vIndex in state.contract_selected.anticipated_payments) {
        let anticipated_payment = state.contract_selected.anticipated_payments[vIndex]
        anticipated_payment.time_period =
          'ab ' + new Date(anticipated_payment.from_date).toLocaleDateString('de-DE')
        if (anticipated_payment.to_date) {
          anticipated_payment.time_period =
            anticipated_payment.time_period +
            ' bis ' +
            new Date(anticipated_payment.to_date).toLocaleDateString('de-DE')
        }
        if (anticipated_payment.active) {
          anticipated_payment.time_period = anticipated_payment.time_period + ' (Aktiv)'
          activeIndex = vIndex
        }
      }

      store.commit(
        'set_anticipated_payment_selected',
        state.contract_selected.anticipated_payments[activeIndex]
      )
      if (router.app.$route.name == 'Documents') {
        getDocumentsByContractId()
      }
      if (router.app.$route.name == 'Metering') {
        getMeterReadingsByContractId()
      }
    },
    set_anticipated_payment_selected(state, payload) {
      state.anticipated_payment_selected = payload
    },
    set_documents(state, payload) {
      state.documents_by_contract = payload
    },
    set_email_by_uuid(state, payload) {
      state.emails_by_uuid[payload.key] = payload.value
    },
    set_meter_readings(state, payload) {
      state.meter_readings_by_contract = payload
    },
    set_referrals(state, payload) {
      state.referrals = payload
      state.referrals_loaded = true
    },
    set_referral_code(state, payload) {
      if (payload) state.referral_code = payload.voucher.code
      else state.referral_code = null
    },
    reset_loaded_values(state) {
      state.customers_loaded = false
      state.referrals_loaded = false
    },
    set_obis_codes(state, payload) {
      state.obis_codes = payload
    },
  },
  plugins: [vuexLocal.plugin],
})

export default store
