<!-- eslint-disable vue/multi-word-component-names -->

<template>
  <div></div>
</template>

<script>
export default {
  mounted() {
    // Logout the user which initalizes login again
    this.$store.commit('logout')
  },
}
</script>
