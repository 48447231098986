<template>
  <div>
    <HeaderComponent
      :title="$vuetify.lang.t('$vuetify.my_data.header_location')"
      :location="location"
      class="mb-14" />
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.my_data.widget_data_contact_title')"
          :data="contactData" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.my_data.widget_data_billing_title')"
          :data="billingData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_title')"
          :data="supplyData" />
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6" xl="6">
        <WidgetData
          :title="$vuetify.lang.t('$vuetify.my_data.widget_data_banking_title')"
          :data="bankingData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <WidgetSEPA :call-text="$vuetify.lang.t('$vuetify.metering.widget_contact_call_text')" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent'
import WidgetData from '../components/widgets/WidgetData.vue'
import WidgetSEPA from '../components/widgets/dashboard/WidgetSEPA.vue'
import { mapState } from 'vuex'
import { getCustomerContract } from '../api/api'

export default {
  name: 'MyData',
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.my_data.header_location'),
          disabled: false,
          href: '/my-data',
        },
      ],
    }
  },
  mounted() {
    getCustomerContract()
    this.$store.commit('set_drawer_selected', 1)
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contract_selected: state => state.contract_selected,
    }),
    contactData: function () {
      let data = [
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_salutation'),
          value: this.customer_selected.salutation ? this.customer_selected.salutation : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_person_title'),
          value: this.customer_selected.title ? this.customer_selected.title : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_first_name'),
          value: this.customer_selected.first_name ? this.customer_selected.first_name : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_last_name'),
          value: this.customer_selected.last_name ? this.customer_selected.last_name : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_email'),
          value: this.customer_selected.email ? this.customer_selected.email : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_phone'),
          value: this.customer_selected.phone ? this.customer_selected.phone : '--',
        },
      ]

      if (this.customer_selected.customer_type == 'person') {
        data.push({
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_date_of_birth'),
          value: this.customer_selected.date_of_birth
            ? new Date(this.customer_selected.date_of_birth).toLocaleDateString('de-DE')
            : '--',
        })

        return data
      } else {
        let companyData = [
          {
            key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_name'),
            value: this.customer_selected.name ? this.customer_selected.name : '--',
          },
          {
            key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_district_court'),
            value: this.customer_selected.district_court
              ? this.customer_selected.district_court
              : '--',
          },
          {
            key: this.$vuetify.lang.t(
              '$vuetify.my_data.widget_data_contact_commercial_register_number'
            ),
            value: this.customer_selected.commercial_register_number
              ? this.customer_selected.commercial_register_number
              : '--',
          },
          {
            key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_contact_vat_number'),
            value: this.customer_selected.vat_number ? this.customer_selected.vat_number : '--',
          },
        ]
        data = data.concat(companyData)

        return data
      }
    },
    billingData: function () {
      return [
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_address_type'),
          value: this.contract_selected.billing_address.address_type
            ? this.contract_selected.billing_address.address_type
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_street'),
          value: this.contract_selected.billing_address.street
            ? this.contract_selected.billing_address.street
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_house_number'),
          value: this.contract_selected.billing_address.street_number
            ? this.contract_selected.billing_address.street_number
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_address_addition'),
          value: this.contract_selected.billing_address.address_addition
            ? this.contract_selected.billing_address.address_addition
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_plz'),
          value: this.contract_selected.billing_address.zip_code
            ? this.contract_selected.billing_address.zip_code
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_city'),
          value: this.contract_selected.billing_address.city
            ? this.contract_selected.billing_address.city
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_billing_district'),
          value: this.contract_selected.billing_address.district
            ? this.contract_selected.billing_address.district
            : '--',
        },
      ]
    },
    supplyData: function () {
      return [
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_street'),
          value: this.contract_selected.supply_address.street
            ? this.contract_selected.supply_address.street
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_house_number'),
          value: this.contract_selected.supply_address.street_number
            ? this.contract_selected.supply_address.street_number
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_address_addition'),
          value: this.contract_selected.supply_address.address_addition
            ? this.contract_selected.supply_address.address_addition
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_plz'),
          value: this.contract_selected.supply_address.zip_code
            ? this.contract_selected.supply_address.zip_code
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_city'),
          value: this.contract_selected.supply_address.city
            ? this.contract_selected.supply_address.city
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_supply_address_district'),
          value: this.contract_selected.supply_address.district
            ? this.contract_selected.supply_address.district
            : '--',
        },
      ]
    },
    bankingData: function () {
      return [
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_banking_payment_method_type'),
          value: this.contract_selected.payment_method.payment_method_type
            ? this.contract_selected.payment_method.payment_method_type
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_banking_full_name'),
          value: this.contract_selected.payment_method.full_name
            ? this.contract_selected.payment_method.full_name
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_banking_iban'),
          value: this.contract_selected.payment_method.iban
            ? this.contract_selected.payment_method.iban
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_banking_bic'),
          value: this.contract_selected.payment_method.bic
            ? this.contract_selected.payment_method.bic
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_banking_bank_name'),
          value: this.contract_selected.payment_method.bank_name
            ? this.contract_selected.payment_method.bank_name
            : '--',
        },
        {
          key: this.$vuetify.lang.t('$vuetify.my_data.widget_data_banking_mandate_reference'),
          value: this.contract_selected.payment_method.mandate_reference
            ? this.contract_selected.payment_method.mandate_reference
            : '--',
        },
      ]
    },
  },
  components: { HeaderComponent, WidgetData, WidgetSEPA },
}
</script>
