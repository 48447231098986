<template>
  <v-card class="pa-4" elevation="1" rounded="lg" height="100%">
    <!-- Title -->
    <v-card-title class="mb-1 ft-700">
      {{ $vuetify.lang.t('$vuetify.widget_email.title') }}
    </v-card-title>
    <!-- Salutation -->
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row dense>
          <v-col cols="12" md="8" lg="6">
            <v-radio-group v-model="radioGroup" row>
              <v-radio v-for="n in 3" :key="n" :value="n" color="accent">
                <template #label>
                  <div class="ft-500">{{ raidoOptions[n - 1] }}</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <!-- Row 1 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="firstName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_email.first_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="lastName"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_email.last_name')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 2 -->
        <v-row dense>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="email"
              :rules="emailRules"
              :label="$vuetify.lang.t('$vuetify.widget_email.email')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="pr-3">
            <v-text-field
              v-model="customer_selected.customer_number"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_email.customer_number')"
              required
              outlined
              color="blue"
              disabled
              background-color="text_area_background"></v-text-field>
          </v-col>
        </v-row>
        <!-- Row 3 and 4 -->
        <v-row dense>
          <v-col cols="12" md="12" lg="8" class="pr-3">
            <v-text-field
              v-model="subject"
              :rules="fieldRules"
              :label="$vuetify.lang.t('$vuetify.widget_email.subject')"
              required
              outlined
              color="blue"
              background-color="text_area_background"></v-text-field>
          </v-col>
          <v-col cols="12" md="12" lg="8" class="pr-3">
            <v-textarea
              v-model="message"
              outlined
              :label="$vuetify.lang.t('$vuetify.widget_email.message')"
              :counter="300"
              :rules="messageRules"
              required
              color="blue"
              background-color="text_area_background"></v-textarea>
          </v-col>
        </v-row>
        <!-- Send button -->
        <v-row>
          <v-col cols="12" md="8" lg="6">
            <v-btn
              height="45"
              :disabled="!valid"
              color="accent"
              class="mr-4 mb-1 text-none ft-700"
              @click="validate">
              {{ $vuetify.lang.t('$vuetify.widget_email.button_text_send') }}
            </v-btn>

            <!-- <v-btn
              height="45"
              color="primary"
              class="mr-4 mb-1 text-none ft-700"
              @click="reset"
            >
              {{ $vuetify.lang.t("$vuetify.widget_email.button_text_reset") }}
            </v-btn> -->
          </v-col>
        </v-row>
      </v-form>
      <!-- Alert -->
      <v-row>
        <v-col cols="12" md="8" lg="6">
          <v-alert
            v-show="showAlert"
            class="mt-2"
            :color="alertType == 'error' ? 'error' : 'accent'"
            dense
            :type="alertType">
            {{ alertMessage }}
          </v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { postEmail } from '../../api/api'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      valid: true,
      firstName: '',
      lastName: '',
      email: '',
      subject: '',
      fieldRules: [v => !!v || this.$vuetify.lang.t('$vuetify.widget_email.text_required')],
      emailRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_email.text_required'),
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          this.$vuetify.lang.t('$vuetify.widget_email.syntax'),
      ],
      radioGroup: 1,
      raidoOptions: [
        this.$vuetify.lang.t('$vuetify.widget_email.radio_woman'),
        this.$vuetify.lang.t('$vuetify.widget_email.radio_man'),
        this.$vuetify.lang.t('$vuetify.widget_email.radio_other'),
      ],
      message: '',
      messageRules: [
        v => !!v || this.$vuetify.lang.t('$vuetify.widget_email.text_required'),
        v =>
          (v && v.length <= 300) || this.$vuetify.lang.t('$vuetify.widget_email.text_max_letters'),
      ],
      showAlert: false,
      alertType: 'success',
      alertMessage: this.$vuetify.lang.t('$vuetify.widget_email.alert_success'),
    }
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
    }),
  },
  mounted() {
    // Set initial data
    this.firstName = this.customer_selected.first_name
    this.lastName = this.customer_selected.last_name
    this.email = this.customer_selected.email
    this.subject = this.$vuetify.lang.t('$vuetify.widget_email.placeholder_subject')
    switch (this.customer_selected.salutation) {
      case 'Frau':
        this.radioGroup = 1
        break
      case 'Herr':
        this.radioGroup = 2
        break
      default:
        this.radioGroup = 3
        break
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        // Create payload
        let payload = {
          first_name: this.firstName,
          last_name: this.lastName,
          email: this.email,
          customer_number: this.customer_selected.customer_number,
          subject: `CP-${this.customer_selected.customer_number}: ${this.subject}`,
          message: this.message,
        }
        switch (this.radioGroup) {
          case 1:
            payload.salutation = 'Frau'
            break
          case 2:
            payload.salutation = 'Herr'
            break
          default:
            break
        }
        // Call API
        postEmail(payload).then(res => {
          // Show corresponding Alert
          if (res) {
            this.showAlert = true
            this.alertType = 'success'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_email.alert_success'
            )
          } else {
            this.showAlert = true
            this.alertType = 'error'
            this.alertMessage = this.subject = this.$vuetify.lang.t(
              '$vuetify.widget_email.alert_error'
            )
          }
        })
      }
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style scoped>
.hidden-sm-and-down .v-icon {
  color: white !important;
}
</style>
