<template>
  <div>
    <HeaderComponent
      :title="$vuetify.lang.t('$vuetify.upload_meter_reading.header_title')"
      :location="location"
      class="mb-14" />
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <WidgetMeterUpload :data="null" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent'
import WidgetMeterUpload from '../components/widgets/WidgetMeterUpload.vue'
import { getCustomerContract } from '../api/api'
import { mapState } from 'vuex'

export default {
  name: 'UploadMeterReading',
  data() {
    return {
      location: [
        {
          text: this.$vuetify.lang.t('$vuetify.upload_meter_reading.header_location'),
          disabled: false,
          href: '/meter-upload',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      contracts: state => state.customer_selected.contracts,
    }),
  },
  mounted() {
    getCustomerContract()
  },
  methods: {},
  components: { HeaderComponent, WidgetMeterUpload },
}
</script>
