<template>
  <div>
    <LandingComponent
      v-if="status == 'loaded'"
      :offer-data="offerData"
      @accepted="accept"
      @rejected="reject"></LandingComponent>
    <AcceptedRejectedComponent
      v-if="['expired', 'accepted', 'rejected', 'error'].includes(status)"
      :status="status"></AcceptedRejectedComponent>
    <FooterComponent />
    <!-- Loading overlay-->
    <div class="text-center">
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import LandingComponent from '../components/tarif-switch/LandingComponent'
import AcceptedRejectedComponent from '../components/tarif-switch/AcceptedRejectedComponent'
import FooterComponent from '../components/FooterComponent'
import { getOfferData, acceptOffer, rejectOffer } from '../api/api'

export default {
  components: {
    FooterComponent,
    LandingComponent,
    AcceptedRejectedComponent,
  },
  data: () => ({
    status: 'loading',
    loading: false,
    offerData: {},
  }),
  mounted() {
    document.title = 'Dein persönliches Sparangebot | VeganStrom'
    this.loading = true
    getOfferData(this.$route.query.hash).then(response => {
      if (response.status === 'error') {
        this.status = 'error'
      } else if (response.status === 'Accepted') {
        this.status = 'accepted'
      } else if (response.expired) {
        this.status = 'expired'
      } else {
        this.status = 'loaded'
        this.offerData = response
      }
      this.loading = false
    })
  },
  methods: {
    accept() {
      this.loading = true
      acceptOffer(this.$route.query.hash).then(success => {
        if (success) {
          this.status = 'accepted'
        } else {
          this.status = 'error'
        }
        this.loading = false
      })
    },
    reject() {
      this.loading = true
      rejectOffer(this.$route.query.hash).then(success => {
        if (success) {
          this.status = 'rejected'
        } else {
          this.status = 'error'
        }
        this.loading = false
      })
    },
  },
}
</script>
