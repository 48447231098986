<template>
  <div class="text">
    <!-- Header -->
    <div
      :style="`${
        $vuetify.breakpoint.mobile ? 'height: 120px' : 'height: 95px; position: relative;'
      } width: 100%; background-color: white; z-index: 2`">
      <v-row class="width-container mx-auto mt-2" align="center">
        <v-col style="display: contents">
          <img
            class="ml-10 mt-3"
            style="max-width: 240px"
            src="../../../public/images/logo-veganstrom.svg" />
          <p style="margin-top: 38px; margin-left: 5px; font-weight: 600" class="text-caption">
            Gegen Tierleid
          </p>
        </v-col>
        <v-col
          :align="`${$vuetify.breakpoint.mobile ? 'middle' : 'right'}`"
          :class="`${$vuetify.breakpoint.mobile ? 'pt-1' : 'pt-7'}`">
          <a
            class="mr-10"
            href="https://www.veganstrom.com/hausstrom/"
            target="_blank"
            style="font-size: 1.2rem; color: black; text-decoration: none">
            Tarifrechner
          </a>
        </v-col>
      </v-row>
    </div>
    <!-- Blue div -->
    <div style="width: 100%; background-image: linear-gradient(to right, #009ec1 0%, #5dc5db 100%)">
      <div
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        :style="`${$vuetify.breakpoint.mobile ? 'padding-top: 150px' : 'padding-top: 220px'}`">
        <v-row>
          <v-col cols="12" md="6">
            <h1
              style="
                font-size: 2.4rem;
                font-weight: 500;
                background-color: white;
                width: fit-content;
              "
              class="mb-3 px-5 py-2">
              Hier ist Dein
            </h1>
            <h1
              style="
                font-size: 2.4rem;
                font-weight: 500;
                background-color: white;
                width: fit-content;
              "
              class="px-5 py-2">
              persönliches Sparangebot!
            </h1>
          </v-col>
          <v-col cols="12" md="6" class="pb-0">
            <v-img
              style="z-index: 1"
              max-width="500px"
              src="../../../public/images/women_happy_cut.png"></v-img
          ></v-col>
        </v-row>
      </div>
    </div>
    <!-- Wave -->
    <div v-show="!$vuetify.breakpoint.smAndDown" style="width: 100%; position: relative">
      <v-row style="margin-top: 11px">
        <v-col class="pa-0" cols="6">
          <svg
            class="gradient-svg"
            viewBox="0 0 2561 500"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
              <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                <stop offset="0%" stop-color="#009ec1" />
                <stop offset="100%" stop-color="#2EB1CE" />
              </linearGradient>
            </defs>
            <path
              fill="rgba(47, 73, 94, 1)"
              d="M 0 108 C 475 108 475 414 950 414 L 950 414 L 950 0 L 0 0 ZM 949 414 C 1445.5 414 1445.5 73 1942 73 L 1942 73 L 1942 0 L 949 0 ZM 1941 73 C 2251 73 2251 0 2561 0 L 2561 0 L 2561 0 L 1941 0 Z"
              stroke-width="0"></path>
          </svg>
        </v-col>
      </v-row>
    </div>
    <!-- Offers -->
    <div
      style="width: 100%; background-color: white; padding-top: 0px; padding-bottom: 20px"
      class="mt-10">
      <div class="width-container mx-auto" style="font-size: 1.2rem">
        <p
          id="offer"
          :class="$vuetify.breakpoint.smAndDown ? 'pl-4' : ''"
          style="font-size: 2.4rem">
          UNSER ANGEBOT FÜR DICH:
        </p>
        <br />
        <br />
        <v-row class="pb-8">
          <v-col align="center">
            <h1 style="font-size: 2.4rem; font-weight: 500" class="mb-12">Dein aktueller Tarif</h1>
            <v-card
              style="background-color: #f5f8f6"
              class="mx-auto rounded-xl px-8 pb-8 pt-4 mt-6 text-left"
              max-width="320"
              outlined>
              <center style="margin-top: 1rem; min-height: 71px">
                <!-- <v-img max-width="250px" src="../../../public/images/VgsBasic.png"></v-img> -->
                <span style="font-size: 31px; font-weight: 500" class="text">
                  <span style="color: #82c89d">Vegan</span>Strom
                </span>
                <span
                  style="
                    font-size: 15px;
                    font-weight: 600;
                    font-family: Manrope, sans-serif;
                    white-space: nowrap;
                  "
                  >{{ offerData.tariff_name_current }}</span
                >
              </center>
              <center class="mt-6 mb-14">
                <!-- Bubbles -->
                <div
                  style="
                    border-radius: 50%;
                    width: 180px;
                    height: 180px;
                    background-color: #dff3e6;
                    position: absolute;
                    top: 120px;
                    left: 60px;
                  "></div>
                <div
                  style="
                    border-radius: 50%;
                    width: 100px;
                    height: 100px;
                    background-color: #dff3e6;
                    position: absolute;
                    top: 140px;
                    left: 190px;
                  "></div>
                <p style="font-size: 65px; position: relative; margin-top: 3.6rem">
                  <sup>€</sup>{{ getEuro(germanNumberFormat(offerData.anticipated_payment_current))
                  }}<sup class="ml-2">{{
                    getCent(germanNumberFormat(offerData.anticipated_payment_current))
                  }}</sup>
                </p>
                <p
                  style="
                    font-size: 12px;
                    position: relative;
                    margin-top: -30px;
                    margin-right: 50px;
                  ">
                  Abschlag pro Monat**
                </p>
              </center>
              <ul class="checkmark">
                <li class="mt-2">Tarif unterstützt PETA</li>
                <li class="mt-2">{{ offerData.contract_term_current }} Monate Vertragslaufzeit</li>
                <li class="mt-2">
                  {{ offerData.price_guarantee_months_current }} Monate Preisgarantie
                </li>
              </ul>
              <v-card-actions class="mt-6">
                <v-btn
                  style="
                    font-size: 1.6rem;
                    letter-spacing: normal;
                    text-transform: none;
                    margin-left: -60px;
                  "
                  width="360px"
                  height="100px"
                  color="primary"
                  rounded
                  @click="$emit('rejected')">
                  <p class="mt-2" style="color: black">
                    Ich möchte nicht sparen und <br />
                    das Angebot <span style="color: white">ablehnen.</span>
                  </p>
                </v-btn>
              </v-card-actions>
              <p class="mt-4 text-caption">
                **Tarif für {{ germanNumberFormat(offerData.annual_consumption) }}kWh/Jahr
              </p>
              <p class="text-caption" style="color: #213037">
                inkl. {{ germanNumberFormat(offerData.energy_price_current) }}Cent/kWh Arbeitspreis
                und {{ germanNumberFormat(offerData.base_price_current_monthly) }}€/Monat Grundpreis
              </p>
            </v-card>
          </v-col>
          <v-col align="center">
            <h1 style="font-size: 2.4rem; font-weight: 500" class="mb-12">Dein Wechselangebot</h1>
            <v-card
              style="background-color: #f5f8f6; border: rgb(181, 227, 198) dashed 8px"
              class="mx-auto rounded-xl px-8 pb-8 pt-4 mt-6 text-left"
              max-width="350"
              outlined>
              <v-img
                style="position: absolute; top: -68px; right: -67px"
                src="../../../public/images/recommended.svg"></v-img>
              <center style="margin-top: 1rem; min-height: 71px">
                <!-- <v-img max-width="250px" src="../../../public/images/VgsBasic.png"></v-img> -->
                <span style="font-size: 31px; font-weight: 500" class="text">
                  <span style="color: #82c89d">Vegan</span>Strom
                </span>
                <span
                  style="
                    font-size: 15px;
                    font-weight: 600;
                    font-family: Manrope, sans-serif;
                    white-space: nowrap;
                  "
                  >{{ offerData.tariff_name_offer }}</span
                >
              </center>
              <center class="mt-6 mb-14">
                <!-- Bubbles -->
                <div
                  style="
                    border-radius: 50%;
                    width: 200px;
                    height: 260px;
                    background-color: #dff3e6;
                    position: absolute;
                    top: 120px;
                    left: 60px;
                  "></div>
                <div
                  style="
                    border-radius: 50%;
                    width: 110px;
                    height: 150px;
                    background-color: #dff3e6;
                    position: absolute;
                    top: 150px;
                    left: 190px;
                  "></div>
                <p style="font-size: 50px; position: relative; margin-top: 3.6rem">
                  <sup>€</sup
                  ><span style="text-decoration: line-through">{{
                    getEuro(germanNumberFormat(offerData.anticipated_payment_current))
                  }}</span
                  ><sup class="ml-2" style="text-decoration: line-through">{{
                    getCent(germanNumberFormat(offerData.anticipated_payment_current))
                  }}</sup>
                </p>
                <p
                  style="
                    font-size: 12px;
                    position: relative;
                    margin-top: -30px;
                    margin-right: 50px;
                  ">
                  Abschlag pro Monat**
                </p>
                <p style="font-size: 65px; position: relative">
                  <sup>€</sup>{{ getEuro(germanNumberFormat(offerData.anticipated_payment_offer))
                  }}<sup class="ml-2">{{
                    getCent(germanNumberFormat(offerData.anticipated_payment_offer))
                  }}</sup>
                </p>
                <p
                  style="
                    font-size: 12px;
                    position: relative;
                    margin-top: -30px;
                    margin-right: 50px;
                  ">
                  Effektivpreis*
                </p>
              </center>
              <ul class="checkmark">
                <li class="mt-2">Tarif unterstützt PETA</li>
                <li class="mt-2">{{ offerData.contract_term_offer }} Monate Vertragslaufzeit</li>
                <li class="mt-2">
                  {{ offerData.price_guarantee_months_offer }} Monate Preisgarantie
                </li>
                <li style="background-color: #82c89d; border-radius: 5px" class="mt-2">
                  {{ offerData.saving_amount_int }} €* Ersparnis über die Vertragslaufzeit
                </li>
              </ul>
              <v-card-actions class="mt-6">
                <v-btn
                  class="accept"
                  style="
                    font-size: 1.6rem;
                    letter-spacing: normal;
                    text-transform: none;
                    margin-left: -62px;
                  "
                  width="380px"
                  height="100px"
                  color="accent"
                  rounded
                  @click="$emit('accepted')">
                  <p class="mt-2" style="color: black">
                    Ich möchte sparen und <br />
                    das Angebot <span style="color: white">annehmen.</span>
                  </p>
                </v-btn>
              </v-card-actions>
              <p class="mt-4 text-caption">
                **Tarif für {{ germanNumberFormat(offerData.annual_consumption) }}kWh/Jahr
              </p>
              <p class="mt-4 text-caption">
                *ausgehend von Deinem aktuellen Verbrauch und Konditionen
              </p>
              <p class="text-caption" style="color: #213037">
                inkl. {{ germanNumberFormat(offerData.energy_price_offer) }}Cent/kWh Arbeitspreis
                und {{ germanNumberFormat(offerData.base_price_offer_monthly) }}€/Monat Grundpreis
              </p>
            </v-card>
          </v-col>
        </v-row>
        <div :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"></div>
      </div>
    </div>
    <!-- White Offer div -->
    <div
      :style="`
        position: relative;
        min-height: 600px;
        width: 100%;
        padding-top: ${$vuetify.breakpoint.sm ? '20px' : '50px'};
        padding-bottom: 80px;
        background-color: transparent;
      `">
      <div
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        style="font-size: 1.2rem">
        <h1 style="width: fit-content; font-size: 2.4rem; font-weight: 500">
          Deine Ersparnis über Vertragslaufzeit:
          <span style="color: #82c89d; font-size: 70px">{{ offerData.saving_amount_int }}€*</span>
        </h1>
        <br />
        <p style="font-family: Manrope, sans-serif">
          Wechsel
          <span style="color: #82c89d; font-weight: bold">jetzt</span>
          in den VeganStrom {{ offerData.tariff_name_offer }} Tarif. <br />
          Damit profitierst Du ab dem {{ germanDateFormat(offerData.start_date_offer) }} von
          {{ offerData.price_guarantee_months_offer }} Monaten Preissicherheit. <br />Im
          {{ offerData.tariff_name_offer }}
          Tarif sparst Du außerdem unglaubliche
          <b>{{ offerData.saving_amount_int }}€*</b> über die gesamte Vertragslaufzeit.
          <br />
          <br />
          <span style="color: #ff6666; font-weight: 600">Wichtig:</span> Dieses Angebot ist nur für
          die nächsten {{ offerData.offer_duration_days }} Tage gültig.
        </p>
        <br />
        <v-row>
          <v-col>
            <v-img
              class="ml-6 mt-8"
              max-width="450"
              src="../../../public/images/advantage_squirrel.png"></v-img>
          </v-col>
          <v-col style="font-family: Manrope, sans-serif">
            <ul class="ml-4 mt-14" style="list-style: none; line-height: 3rem; font-weight: 600">
              <li>
                <svg
                  class="mr-2"
                  y="20"
                  width="20"
                  height="20"
                  viewBox="0 0 1792 1192"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#82c89d"
                    d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z" />
                </svg>
                {{ offerData.price_guarantee_months_offer }} Monate Preisgarantie
              </li>
              <li>
                <svg
                  class="mr-2"
                  y="20"
                  width="20"
                  height="20"
                  viewBox="0 0 1792 1192"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#82c89d"
                    d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z" />
                </svg>
                {{ offerData.contract_term_offer }} Monate Vertragslaufzeit
              </li>
              <li>
                <svg
                  class="mr-2"
                  y="20"
                  width="20"
                  height="20"
                  viewBox="0 0 1792 1192"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill="#82c89d"
                    d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z" />
                </svg>
                100% tierfreundlicher Ökostrom
              </li>
            </ul>
          </v-col>
        </v-row>
      </div>
    </div>
    <!-- Steps -->
    <div
      style="
        min-height: 600px;
        width: 100%;
        padding-top: 150px;
        padding-bottom: 150px;
        background-color: #f5f8f6;
      "
      class="mt-10">
      <div
        :class="`width-container ${$vuetify.breakpoint.mobile ? 'mx-4' : 'mx-auto'}`"
        style="font-size: 1.4rem">
        <p style="font-size: 2.4rem">Und so einfach geht's:</p>
        <v-row>
          <v-col>
            <ul>
              <li>
                <p>
                  <span style="color: #82c89d">Überblick verschaffen:</span>
                  <br />
                  <span style="font-family: Manrope, sans-serif; font-size: 1.2rem">
                    Direkt nachfolgend findest Du erstens Deine aktuellen Konditionen und zweitens
                    unser exklusives Angebot mit längerer Preissicherheit.</span
                  >
                </p>
              </li>
              <li>
                <p>
                  <span style="color: #82c89d">Vergleiche und entscheide:</span>
                  <br /><span style="font-family: Manrope, sans-serif; font-size: 1.2rem"
                    >Nimm Dir einen Moment Zeit, um beide Optionen gegenüberzustellen. Überzeugt
                    Dich unser Angebot?</span
                  >
                </p>
              </li>
              <li>
                <p>
                  <span style="color: #82c89d; cursor: pointer" @click="scrollToOffer"
                    >Klick auf Angebot annehmen:</span
                  >
                  <br /><span style="font-family: Manrope, sans-serif; font-size: 1.2rem"
                    >Solltest Du Dich für unser Angebot entscheiden, senden wir dir so schnell wie
                    möglich Deine neuen Vertragsunterlagen zu.</span
                  >
                </p>
              </li>
              <li>
                <p>
                  <span style="color: #ff6666">ODER: </span>
                  <span style="color: #82c89d">Klick auf Angebot ablehnen:</span>
                  <br /><span style="font-family: Manrope, sans-serif; font-size: 1.2rem"
                    >Möchtest Du nicht wechseln, bleibt alles wie gewohnt.</span
                  >
                </p>
              </li>
            </ul>
            <p style="font-family: Manrope, sans-serif; font-size: 1rem">
              <span style="font-weight: 600">Wichtig:</span> Dieses Angebot steht dir nur für die
              nächsten {{ offerData.offer_duration_days }} Tage zur Verfügung.
            </p>
          </v-col>
          <v-col align="center">
            <v-img max-width="400px" src="../../../public/images/socket.png"></v-img>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['offerData'],
  mounted() {
    document.title = 'Dein persönliches Sparangebot | VeganStrom'
  },
  methods: {
    scrollToOffer() {
      this.$vuetify.goTo('#offer')
    },
    germanNumberFormat(price) {
      return Number(price)
        .toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
        .replace('.', '')
    },
    getEuro(price) {
      return parseInt(price)
    },
    getCent(price) {
      return price.split(',')[1]
    },
    germanDateFormat(date) {
      return new Date(date).toLocaleDateString('de-DE')
    },
  },
}
</script>

<style scoped>
.checkmark {
  list-style: none;
  padding-left: 0;
}
.checkmark li {
  position: relative;
  padding-left: 1.5em; /* space to preserve indentation on wrap */
}
.width-container {
  max-width: 1100px;
  position: relative;
}
.text {
  font-family: 'Baloo' !important;
  font-weight: 500 !important;
  line-height: 1.4;
  letter-spacing: normal;
}

/* Animation */
/* Animation property */
.accept {
  animation: wiggle 2s linear infinite;
}

/* Keyframes */
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-5deg);
  }
  20% {
    transform: rotateZ(3deg);
  }
  25% {
    transform: rotateZ(-3deg);
  }
  30% {
    transform: rotateZ(2deg);
  }
  35% {
    transform: rotateZ(-2deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
.gradient-svg path {
  fill: url(#gradient);
}
</style>
