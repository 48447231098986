<template>
  <div>
    <v-row class="mt-1">
      <v-col cols="4">
        <!-- Breadcumbs -->
        <v-breadcrumbs class="pa-0" :items="location">
          <template #item="{ item }">
            <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-col>

      <v-col v-show="!$vuetify.breakpoint.xs" cols="4" class="ml-auto mb-2 text-right">
        <!-- Settings -->
        <span class="mr-6" style="cursor: pointer" @click="settings">
          <v-icon color="black" size="large">mdi-account-cog-outline</v-icon>
          <span class="text-body-2 ml-1">
            {{ $vuetify.lang.t('$vuetify.header.settings') }}
          </span>
        </span>
        <!-- Logout -->
        <span style="cursor: pointer" @click="logout">
          <v-icon color="black" size="large">mdi-power-standby</v-icon>
          <span class="text-body-2 ml-1">
            {{ $vuetify.lang.t('$vuetify.header.logout') }}
          </span>
        </span>
      </v-col>
    </v-row>
    <!-- Multiple contracts select -->
    <v-row v-if="!disableSelection" justify="space-between">
      <v-col v-if="!disableTitle" cols="12" sm="6" md="8" lg="8" xl="8">
        <h2 v-if="!title" class="mt-8 ft-700-24">
          {{ $vuetify.lang.t('$vuetify.header.hello') }}
          {{ customer_selected.name }}
        </h2>
        <h2 class="mt-8 ft-700-24" v-html="title"></h2>
      </v-col>
      <v-col v-if="customers.length > 1" class="" cols="6" sm="3" md="2" lg="2" xl="2">
        <span class="ft-500-caption">
          {{ $vuetify.lang.t('$vuetify.header.customer') }}
        </span>
        <v-select
          v-model="customer_selected"
          class="ft-700"
          solo
          dense
          :items="customers"
          item-text="name"
          return-object></v-select>
      </v-col>
      <v-col v-if="contracts && contracts.length > 1" cols="6" sm="3" md="2" lg="2" xl="2">
        <span class="ft-500-caption">
          {{ $vuetify.lang.t('$vuetify.header.contract') }}
        </span>
        <v-select
          v-model="contract_selected"
          class="ft-700"
          solo
          dense
          :items="contracts"
          item-text="contract_number"
          return-object></v-select>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar">
      {{
        snackbarType == 'contract'
          ? $vuetify.lang.t('$vuetify.header.contract_selected_dialog_text_contract')
          : $vuetify.lang.t('$vuetify.header.contract_selected_dialog_text_customer')
      }}

      <template #action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="snackbar = false">
          {{ $vuetify.lang.t('$vuetify.header.contract_selected_dialog_close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { settings_url } from '../auth/auth'

export default {
  props: ['title', 'location', 'disableTitle', 'disableSelection'],
  data() {
    return {
      snackbar: false,
      snackbarType: 'customer',
    }
  },
  mounted() {},
  methods: {
    logout() {
      // Delete the user data and set loggedIn to false
      this.$store.commit('logout')
    },
    settings() {
      // Navigate to authentication server settings url (password change for now)
      document.location = settings_url
    },
  },
  computed: {
    ...mapState({
      customer_selected: state => state.customer_selected,
      contracts: state => state.customer_selected.contracts,
      customers: state => state.customers,
    }),
    customer_selected: {
      get: function () {
        return this.$store.getters.customer_selected
      },
      set: function (newValue) {
        this.$store.commit('set_customer_selected', newValue)
        this.snackbarType = 'customer'
        this.snackbar = true
      },
    },
    contract_selected: {
      get: function () {
        return this.$store.getters.contract_selected
      },
      set: function (newValue) {
        this.$store.commit('set_contract_selected', newValue)
        this.snackbarType = 'contract'

        this.snackbar = true
      },
    },
  },
}
</script>

<style>
.v-breadcrumbs a {
  color: rgba(0, 0, 0, 0.5) !important;
  font-size: 12px;
}
</style>
